html{
    --mdc-radio-disabled-selected-icon-opacity:0.38;
    --mdc-radio-disabled-unselected-icon-opacity:0.38;
    --mdc-radio-state-layer-size:18px;
}
.mat-mdc-radio-group{
    .mat-mdc-radio-button{
        line-height: 18px;
        margin: 0 7px 0 0;
        --mdc-radio-state-layer-size: 18px;
        .mdc-form-field{
            color:#000;
            -moz-osx-font-smoothing:grayscale;
            -webkit-font-smoothing:antialiased;
            font-family:var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Karla, Roboto, "Helvetica Neue", sans-serif));
            font-size:var(--mdc-typography-body2-font-size, 14px);
            line-height:var(--mdc-typography-body2-line-height, 20px);
            font-weight:var(--mdc-typography-body2-font-weight, 400);
            letter-spacing:var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
            text-decoration:var(--mdc-typography-body2-text-decoration, inherit);
            text-transform:var(--mdc-typography-body2-text-transform, none);
            label{
                margin: 0px;
                padding-left: 5px;
                line-height: 18px;
                cursor: pointer;
            }
        }
        .mdc-radio {
            padding: 0.5px !important;
            width: 18px;
            height: 18px;
        }
        .mat-mdc-radio-touch-target{
            height: 100%;
            width: 100%;
        }
        .mdc-radio__background{
          width: 18px;
          height: 18px;
          .mdc-radio__outer-circle{
            width: 18px;
            height: 18px;
            border: 1px solid #bbb !important;
            background-color: #fff;
          }
          .mdc-radio__inner-circle {
            height: 8px;
            width: 8px;
            transform: translate(-50%,-50%) !important;
            left: 50%;
            top: 50%;
            border-color: transparent !important;
            border-width: 4px;
          }
        }
        &.mat-mdc-radio-checked{
            .mdc-radio__background{
                .mdc-radio__inner-circle{
                    border-color: #1155cc !important;
                }
            }
        }
    }
}