
.grid-detail, .grid-view{
  border: 1px solid #444;
  border-left-color: transparent;
  margin-bottom: 44px;
  font-family: 'Karla', sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  overflow: auto; 
  max-width: 65%; 
  min-height: 500px;
  .grid-title{
    background-color: #fafafa !important;
    line-height: 32px;
    text-align: center;
  }
  .grid-detail-row{
    border-bottom: 1px solid #ccc;
    max-height: 500px;
    overflow: auto;
    > div{
      border: 1px solid #ccc;
      border-bottom: none;
      border-right: none;
      line-height: 37px;
      padding: 0.2em;
      &.notes{
        line-height: 22px;
      }
      &.mark{
        background-color: #fafafa !important;
        font-size: 0.875rem;
        padding: 0;
        span{
          border: 0.5em solid #fff !important;
          height: 100% !important;
          display: block !important;
          padding: 0.2em;
          width: 100%;
          line-height: 24px;
        }
      }
    }
  }
  ~ .grid-detail{
    border-left: none;
  }
}
.grid-view{
  border-color: #ccc;
  border-left: none;
  border-bottom: none;
  min-height: auto;
  .grid-title{
    border-left: 1px solid #ccc;
  }
}
.grid-detail-view{
  resize: horizontal; 
  overflow: auto; 
  float: left; 
  max-width: 70%; 
  min-width: 35%; 
  width: 50%;
  .grid{
    position: unset;
    padding: 0px;
    > div:last-child{
      position: absolute;
      width: calc(100% - 1.5em) !important;
      > div{
        padding: 0px;
      }
    }
  }
  .ag-root-wrapper{
    border-color: #444;
    border-right: none;
  }
}
.border-bottom{
  border-bottom: 1px solid #ccc !important;
}
.border-top{
  border-top: 1px solid #ccc !important;
}
.border-right{
  border-right: 1px solid #ccc !important;
}
.border-left{
  border-left: 1px solid #ccc !important;
}
body .mat-mdc-menu-panel.share-menu{
  min-width: 160px !important;
}

body .no-data-msg{
  background-color: #f5f5f5;
  height: 66px;
  line-height: 66px;
  border-bottom: 1px solid #ccc;
  .material-symbols-sharp{
    color: #1e293b;
    vertical-align: middle;
    font-size: 20px;
    line-height: 18px;
  }
}

.grid-detail-main{
  position: relative;
  padding: 0 1.5em;
  .data-grid-select-columns{
    position: absolute;
    right: auto;
    left: -9px;
    z-index: 99;
    height: 35px;
    text-align: right;
    top: 0px;
    border: 1px solid #444;
    border-right-color: #f5f5f5;
    mat-form-field.mat-mdc-form-field {
      position: absolute;
      right: auto;
      left: 0px;
      top: 30px;
      width: 200px;
    }
  }
}
.no-selection-columns{
  .grid-detail-main{
    padding: 0px;
    .data-grid-select-columns{
      display: none;
    }
  }
  .grid-detail-view .grid > div:last-child{
    width: 100% !important;
  }
}
