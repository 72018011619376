.boxed-check-radio{
    overflow: auto;
    overflow-x: hidden;
    .mat-mdc-radio-button{
        display: block;
        .mdc-radio{
            display: none;
        }
        .mat-radio-label-content{
            padding: 0px;
        }
        .mdc-form-field{
            display: block;
            letter-spacing: normal;
        }
        .mdc-label{
            border: 1px solid #ccc;
            border-top: none;
            background: #eff5f9;
            padding: 9px 3px;
            width: 100%;
            display: block;
            text-align: center;
            margin-bottom: 0px;
            font-size: 0.87em;
            cursor: pointer;
        }
        &.mat-mdc-radio-checked{
            .mdc-label{
                border: 1px solid #ccc;
                border-top: none;
                background: #0b5394;
                color: #fff;
            }
        }
        &:first-child{
            .mdc-label{
                border-top: 1px solid #ccc;
            }
        }
    }
    .mat-checkbox{
        display: block;
        .mat-checkbox-layout{
            border: 1px solid #ccc;
            border-bottom: none;
            background: #fcfcfc;
            padding: 0px;
            width: 100%;
            display: block;
            text-align: center;
            margin: 0px;
            .mat-checkbox-inner-container{
                display: none;
            }
            .mat-checkbox-label{
                padding: 0px;
                div{
                    div{
                        padding-top: 5px;
                        padding-bottom: 5px;
                        height: 30px;
                        line-height: 20px;
                        border-right: 1px solid #ccc;
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
                .check-icon{
                    padding-top: 3px;
                    .mat-icon {
                        font-size: 18px;
                        color: #fff;
                        opacity: 0;
                    }
                } 
            }
        }
        &:last-child {
            .mat-checkbox-layout {
                border-bottom: 1px solid #ccc;
            }
        }
        &.mat-checkbox-checked{
            .mat-checkbox-layout{
                border: 1px solid #ccc;
                background: #0b5394;
                color: #fff;
                .mat-checkbox-label{
                    .check-icon{
                        .mat-icon {
                            opacity: 1;
                        }
                    } 
                }
            }
        }
    }
}