.project-progress{
    --mdc-linear-progress-active-indicator-height: 6px;
    --mdc-linear-progress-track-height: 6px;
    .mat-mdc-progress-bar{
        width: calc(100% - 100px);
        display: inline-block;
    }
    .progress-text{
        line-height: 18px;
        display: inline-block;
        vertical-align: middle;
    }
}
.project-links{
    li{
        list-style: none;
        display: inline-block;
        border-right: 1px solid #aaa;
        padding: 0 5px;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            border-right: none;
        }
        a{
            color: #1155cc !important;
            cursor: pointer;
        }
    }
}
.project-icon {
    .mat-icon {
        font-size: 85px;
        height: 75px;
        width: 75px;
    }
    img{
        width: 100%;
    }
}   