html {
  --mdc-checkbox-disabled-selected-checkmark-color: #1155cc;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;

  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #000;
  --mdc-checkbox-selected-hover-icon-color: #000;
  --mdc-checkbox-selected-icon-color: #000;
  --mdc-checkbox-selected-pressed-icon-color: #000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #000;
  --mdc-checkbox-selected-hover-state-layer-color: #000;
  --mdc-checkbox-selected-pressed-state-layer-color: #000;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
html {
  --mdc-checkbox-state-layer-size: 18px;
  .mat-mdc-checkbox {
    margin: 6px 7px 0px 0px;
    vertical-align: text-bottom;
    .mdc-form-field {
      color: #000;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-family: var(
        --mdc-typography-body2-font-family,
        var(
          --mdc-typography-font-family,
          Karla,
          Roboto,
          "Helvetica Neue",
          sans-serif
        )
      );
      font-size: var(--mdc-typography-body2-font-size, 14px);
      line-height: var(--mdc-typography-body2-line-height, 20px);
      font-weight: var(--mdc-typography-body2-font-weight, 400);
      letter-spacing: normal;
      text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
      text-transform: var(--mdc-typography-body2-text-transform, none);
      label {
        margin: 0px;
      }
      .mat-mdc-checkbox-touch-target {
        height: 100%;
        width: 100%;
      }
    }
    .mdc-checkbox {
      padding: 0px;
      margin: 0px;
      flex: 0 0 18px;
      height: 18px;
      width: 18px;
      .mdc-checkbox__native-control:enabled
        ~ .mdc-checkbox__background
        .mdc-checkbox__checkmark {
        color: #1155cc !important;
        height: 14px;
        width: 14px;
        margin: 1px auto;
      }
    }
    .mdc-checkbox__background {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      border: 1px solid #bbb !important;
      background-color: #fff !important;
    }
    .mdc-checkbox {
      .mdc-checkbox__native-control[disabled]{
        &~.mdc-checkbox__background{
            background-color: #f1f1f1 !important;
        }
        &:checked~.mdc-checkbox__background{
            background-color: #f1f1f1 !important;
        }
      }
    }
    .mat-mdc-checkbox-ripple,
    .mdc-checkbox__ripple {
      border-radius: 0px;
    }
  }
}
