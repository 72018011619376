html{
  --mdc-switch-disabled-selected-icon-opacity:0.38;
  --mdc-switch-disabled-track-opacity:0.12;
  --mdc-switch-disabled-unselected-icon-opacity:0.38;
  --mdc-switch-handle-height:20px;
  --mdc-switch-handle-shape:10px;
  --mdc-switch-handle-width:20px;
  --mdc-switch-selected-icon-size:18px;
  --mdc-switch-track-height:14px;
  --mdc-switch-track-shape:7px;
  --mdc-switch-track-width:36px;
  --mdc-switch-unselected-icon-size:18px;
  --mdc-switch-selected-focus-state-layer-opacity:0.12;
  --mdc-switch-selected-hover-state-layer-opacity:0.04;
  --mdc-switch-selected-pressed-state-layer-opacity:0.1;
  --mdc-switch-unselected-focus-state-layer-opacity:0.12;
  --mdc-switch-unselected-hover-state-layer-opacity:0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity:0.1;
  --mat-switch-disabled-selected-handle-opacity:0.38;
  --mat-switch-disabled-unselected-handle-opacity:0.38;
  --mat-switch-unselected-handle-size:20px;
  --mat-switch-selected-handle-size:20px;
  --mat-switch-pressed-handle-size:20px;
  --mat-switch-with-icon-handle-size:20px;
  --mat-switch-selected-handle-horizontal-margin:0;
  --mat-switch-selected-with-icon-handle-horizontal-margin:0;
  --mat-switch-selected-pressed-handle-horizontal-margin:0;
  --mat-switch-unselected-handle-horizontal-margin:0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin:0;
  --mat-switch-unselected-pressed-handle-horizontal-margin:0;
  --mat-switch-visible-track-opacity:1;
  --mat-switch-hidden-track-opacity:1;
  --mat-switch-visible-track-transition:transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition:transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width:1px;
  --mat-switch-track-outline-color:transparent;
  --mat-switch-disabled-unselected-track-outline-width:1px;
  --mat-switch-disabled-unselected-track-outline-color:transparent
}
html{
  --mdc-switch-selected-focus-state-layer-color:#3949ab;
  --mdc-switch-selected-handle-color:#3949ab;
  --mdc-switch-selected-hover-state-layer-color:#3949ab;
  --mdc-switch-selected-pressed-state-layer-color:#3949ab;
  --mdc-switch-selected-focus-handle-color:#1a237e;
  --mdc-switch-selected-hover-handle-color:#1a237e;
  --mdc-switch-selected-pressed-handle-color:#1a237e;
  --mdc-switch-selected-focus-track-color:#7986cb;
  --mdc-switch-selected-hover-track-color:#7986cb;
  --mdc-switch-selected-pressed-track-color:#7986cb;
  --mdc-switch-selected-track-color:#7986cb;
  --mdc-switch-disabled-selected-handle-color:#424242;
  --mdc-switch-disabled-selected-icon-color:#fff;
  --mdc-switch-disabled-selected-track-color:#424242;
  --mdc-switch-disabled-unselected-handle-color:#424242;
  --mdc-switch-disabled-unselected-icon-color:#fff;
  --mdc-switch-disabled-unselected-track-color:#424242;
  --mdc-switch-handle-surface-color:var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color:black;
  --mdc-switch-disabled-handle-elevation-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color:#fff;
  --mdc-switch-unselected-focus-handle-color:#212121;
  --mdc-switch-unselected-focus-state-layer-color:#424242;
  --mdc-switch-unselected-focus-track-color:#e0e0e0;
  --mdc-switch-unselected-handle-color:#616161;
  --mdc-switch-unselected-hover-handle-color:#212121;
  --mdc-switch-unselected-hover-state-layer-color:#424242;
  --mdc-switch-unselected-hover-track-color:#e0e0e0;
  --mdc-switch-unselected-icon-color:#fff;
  --mdc-switch-unselected-pressed-handle-color:#212121;
  --mdc-switch-unselected-pressed-state-layer-color:#424242;
  --mdc-switch-unselected-pressed-track-color:#e0e0e0;
  --mdc-switch-unselected-track-color:#e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38)
}
html .mat-mdc-slide-toggle{
  --mdc-form-field-label-text-color:rgba(0, 0, 0, 0.87)
}
html .mat-mdc-slide-toggle.mat-accent{
  --mdc-switch-selected-focus-state-layer-color:#38761d;
  --mdc-switch-selected-handle-color:#38761d;
  --mdc-switch-selected-hover-state-layer-color:#38761d;
  --mdc-switch-selected-pressed-state-layer-color:#38761d;
  --mdc-switch-selected-focus-handle-color:#38761d;
  --mdc-switch-selected-hover-handle-color:#38761d;
  --mdc-switch-selected-pressed-handle-color:#38761d;
  --mdc-switch-selected-focus-track-color:#c5d6e6;
  --mdc-switch-selected-hover-track-color:#c5d6e6;
  --mdc-switch-selected-pressed-track-color:#c5d6e6;
  --mdc-switch-selected-track-color:#c5d6e6
}
html{
  --mdc-switch-state-layer-size:40px
}
html .mat-mdc-slide-toggle{
  --mdc-form-field-label-text-font:Roboto, sans-serif;
  --mdc-form-field-label-text-line-height:20px;
  --mdc-form-field-label-text-size:14px;
  --mdc-form-field-label-text-tracking:0.0178571429em;
  --mdc-form-field-label-text-weight:400
}