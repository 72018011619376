@import "./assets/css/colors.scss";
@import "./assets/css/fonts.scss";
@import "./assets/css/constants.scss";

.ng-trigger-routeAnimations {min-height: calc(100vh - 264px);}

.h3-border-top {
  border-bottom: 0;
  border-image-slice: 1;
  border-left: 0;
  border-right: 0;
  border-top: 14px solid $color_carnation_approx!important;
  padding-top: 14px;
}
.h1 {
  font-family: $font_1, $font_5;
  font-size: 2em!important;
  font-weight: 300!important;
  line-height: 42px!important;
}
.h2 {
  font-family: $font_1, $font_5;
  font-size: 1.5em!important;
  font-weight: 300!important;
  line-height: 36px!important;
}

.simple-page-container {
  font-family: $font_6, $font_2;
  font-size: 1rem;
  margin: 0 auto 0;
  padding-top: 70px;
  font-weight: 300;
  a {
    color: $color_menu_blue;
    &:hover {
      color: $color_menu_blue;
    }
  }
  .content-block-module {
    margin-bottom: 68px;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      padding-top: 83px;
    }
  }
  [id]:before {
    content: "";
    display: block;
    height: 120px;
    margin-top: -120px;
    visibility: hidden;
  }
  ~ .navblock {
    margin-top: -48px!important;
  }
}
.simple-page-header {
  position: relative;
  h1 {
    color: $white;
    font-family: $font_1, $font_2;
    font-size: 2.5em;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }
  .simple-page-header-featured-container {
    height: 140px;
    overflow: hidden;
    position: relative;
    background: url(./assets/images/bg-pattern.png) repeat #222;
  }
}

.simple-page-content {
  margin: 0 auto;
  min-height: calc(100vh - 172px);
}
.footer {
  align-items: center;
  background-color: $color_footer_bg;
  color: $black;
  display: grid;
  justify-items: center;
  padding-left: 0px;
  padding-right: 0px;
  place-items: center;
  position: relative;
  .footer-container {
    display: grid;
    grid-column-gap: 0;
    grid-template-columns: repeat(12,1fr [col-start]);
    width: 100%;
    .footer-inner-container {
      display: flex;
      flex-direction: column;
      grid-column: 1/span 12;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    .footer-slogan {
      font-family: $font_1, $font_2;
      text-align: center;
    }
  }
  .footer-top-row {
    margin-bottom: 0px;
    .footer-logo-link {
      display: block;
      font-family: $font_1;
      font-size: 2em;
      letter-spacing: 0.01em;
      text-decoration: none;
      color: $color_logo_2;
      span{
        color: $color_logo_1;
      }
    }
    .footer-logo-div {
      margin-bottom: 15px;
      .footer-logo{
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
        img {
          height: 48px;
        }
      }
      .footer-name{
        display: inline-block;
      }
    }
    .contact-link{
      display: block;
      font-family: $font_1, $font_2;
      font-size: 1.5em;
      letter-spacing: 0.01em;
      text-decoration: none;
      color: $black;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
      }
    }
  }
  .footer-bottom-row {
    align-content: center;
    display: flex;
    justify-content: space-between;
  }
  .primary-link-wrapper {
    margin-bottom: 12px;
    .primary-link {
      color: $black;
      font-family: $font_6, $font_2;
      font-size: 0.9em;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .footer-social-links-wrapper {
    align-self: flex-end;
    order: 2;
  }
  .secondary-link {
    align-items: center;
    border-left: 1px solid $black;
    color: $black;
    display: inline-flex;
    font-family: $font_1, $font_2;
    font-size: 0.75em;
    margin-bottom: 0px;
    margin-left: 10px;
    padding-left: 12px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:first-child {
      border-left: 0;
      margin-left: 0;
      padding-left: 0;
    }
    &.social-link {
      margin-left: 20px;
    }
  }
  .social-icon {
    height: 30px;
    margin-right: 18px;
    width: 30px;
    &:last-child {
      padding-right: 0;
    }
  }
}
.main-nav {
  background-color: $white;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 4px 4px $black_25;
  padding: 0;
  position: relative;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all .3s ease-in-out;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .nav-bar-mobile {
    align-items: center;
    background-color: $white;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 4px 4px $black_25;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    left: 0;
    padding-left: 22px;
    padding-right: 22px;
    position: fixed;
    top: 0;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all .3s ease-in-out;
    width: 100%;
    z-index: 14;
    .nav-brand {
      margin: 0 auto;
      position: absolute;
      border: none;
      .nav-logo-link {
        display: flex;
        align-items: center;
        font-family: $font_1, $font_2;
        font-size: 1.7em;
        text-decoration: none;
        color: $color_logo_1 !important;
        letter-spacing: 0.01em;
        span {
          color: $color_logo_2;
        }
        img {
          height: 48px;
          width: auto;
          display: inline-block;
          margin-right: 10px;
          margin-top: -3px;
        }
        &.haw-logo {
          height: 24px;
          width: auto;
          svg {
            height: 100%;
            width: auto;
          }
        }
        svg:not(.verily-letter-mark) {
          height: auto;
          width: 100%;
        }
      }
    }
    .nav-mobile-toggle {
      margin-left: auto;
      button {
        align-items: center;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        display: flex;
        height: 26px;
        justify-content: center;
        padding: 0;
        width: 26px;
        &:focus {
          outline: none;
        }
        svg {
          display: none;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
  .nav-items {
    background-color: $white;
    color: $color_cod_gray_approx;
    display: flex;
    flex-direction: column;
    font-family: $font_1, $font_2;
    height: 100%;
    justify-content: space-between;
    max-height: 100%;
    overflow: auto;
    padding: 60px 0 0;
    position: fixed;
    top: 0;
    width: auto;
    z-index: 13;
    .nav-item-group-primary {
      color: $color_cod_gray_approx;
      list-style: none;
      margin: 0;
      padding: 0 0 60px;
      text-align: center;
      width: 100%;
      .nav-item-primary {
        position: relative;
        user-select: none;
        &:last-child .nav-item-primary-title {
          margin-right: 0;
        }
      }
      .nav-item-primary-title {
        align-items: center;
        color: $color_menu_blue;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: 1em;
        font-weight: 400;
        justify-content: center;
        letter-spacing: 2px;
        padding: 25px 0;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        border-top: 2px solid transparent;
        &:focus {
          outline: 0;
        }
        &:hover {
          color: $black;
          border-top: 2px solid $color_logo_1;
        }
      }
      .nav-item-primary-title-wave {
        bottom: 0;
        display: flex;
        justify-content: center;
        line-height: 0;
        position: absolute;
        width: 100%;
      }
      .nav-item-primary[data-nav-state=closed] {
        .nav-item-group-secondary {
          display: none;
        }
        .nav-item-primary-title-wave {
          display: none;
        }
        .nav-group-link:after {
          content: "";
          font-family: $font_0;
          font-size: 18px;
          padding-left: 6px;
          vertical-align: middle;
        }
      }
      .nav-item-primary[data-nav-state=opened] {
        .nav-item-group-secondary {
          display: block;
        }
        .nav-item-primary-title-wave {
          display: block;
        }
        .nav-group-link {
          color: $color_lipstick_approx;
          &:after {
            content: "";
            font-family: $font_0;
            font-size: 18px;
            padding-left: 6px;
            vertical-align: middle;
          }
        }
      }
      .nav-item-group-secondary {
        background-color: $color_porcelain_approx;
        margin: 0;
        padding: 20px 22px 36px;
        text-align: left;
        .nav-item-secondary {
          display: block;
          a {
            border-bottom: 1px solid $color_martini_approx;
            color: $black;
            display: block;
            font-family: $font_1, $font_2;
            font-size: 18px;
            font-weight: 700;
            padding: 16px 0 12px;
            text-decoration: none;
          }
        }
      }
    }
  }
}
.main-nav[data-nav-state=closed] {
  .nav-items {
    display: none;
  }
  .nav-bar-mobile .nav-mobile-toggle button {
    .close {
      display: none;
    }
    .open {
      display: block;
    }
  }
}
.screenreader-text {
  clip: rect(1px,1px,1px,1px);
  position: absolute;
}
@media only screen and (min-width:768px) {
  .footer{
    .footer-top-row {
      .contact-link {
        font-size: 1.1em;
        margin: 18px 0px 10px 0px;
        color: $black;
      }
    }
  }
}
@media only screen and (min-width:900px) {
  .footer {
    .footer-top-row {
      margin-bottom: 0px;
    }
    .primary-link-wrapper {
      margin-bottom: 16px;
    }
    .footer-secondary-links-wrapper {
      margin-top: 8px;
    }
    .footer-social-links-wrapper {
      align-self: unset;
      order: 0;
      font-size: 0.7em;
      line-height: 18px;
      margin-top: 10px;
    }
    .secondary-link {
      border-left: 1px solid $black;
      margin-bottom: 0;
      margin-left: 10px;
      padding-left: 12px;
      &.social-link {
        border-left: 0;
        margin-left: 0;
        opacity: .8;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: opacity .15s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }
    .footer-container .footer-inner-container {
      padding-bottom: 15px;
      padding-top: 15px;
    }
  }
}
@media only screen and (min-width:1024px) {
  .main-nav {
    height: 70px;
    padding: 0px;
    position: fixed;
    top: 0;
    width: calc(100% - 0px);
    z-index: 13;
    .desktop-nav-wrapper {
      align-items: center;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
    }
    .nav-bar-mobile {
      background-color: transparent;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none!important;
      display: inline-flex;
      height: 70px;
      justify-content: left;
      padding: 0;
      position: relative;
      width: unset;
      .nav-brand {
        margin: 0;
        position: relative;
        top: 0;
        .nav-logo-link {
          font-size: 1.2em;
          &.haw-logo {
            height: 30px;
            width: auto;
          }       
        }
      }
      .nav-mobile-toggle {
        display: none;
      }
    }
    .nav-items {
      align-items: center;
      background-color: transparent;
      flex-direction: row;
      height: 70px;
      justify-content: right;
      overflow: visible;
      padding: 0;
      position: unset;
      .nav-item-group-primary {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0;
        .nav-item-primary {
          position: unset;
          &.active {
            &:after {
              right: 64%;
            }
            &:before {
              left: 64%;
            }
            .nav-item-primary-title {
              color: $black;
              border-top: 2px solid $color_logo_1;
            }
          }
        }
        .nav-item-primary-title {
          flex-direction: row;
          height: 70px;
          padding: 15px 0px;
          margin: 0px 10px;
          top: 0;
        }
        .nav-item-group-secondary {
          border-bottom: 1px solid $color_nobel_approx;
          padding: 0;
          text-align: right;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          max-width: 1200px;
          position: absolute;
          right: 0;
          width: 100%;
          &:after {
            background-color: $color_porcelain_approx;
            border-bottom: 1px solid $color_nobel_approx;
            //Instead of the line below you could use @include box-sizing($bs)
            box-sizing: content-box;
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            right: -100%;
          }
          &:before {
            background-color: $color_porcelain_approx;
            border-bottom: 1px solid $color_nobel_approx;
            //Instead of the line below you could use @include box-sizing($bs)
            box-sizing: content-box;
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            left: -100%;
          }
          .nav-item-secondary {
            display: inline-block;
            a {
              border-bottom: 4px solid transparent;
              display: inline-block;
              font-family: $font_3, $font_4;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 2px;
              line-height: 18px;
              margin-left: 85px;
              text-transform: uppercase;
              &.active {
                border-bottom: 4px solid $color_lipstick_approx;
                //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
                transition: border-color .3s ease-in-out;
              }
              &:hover {
                border-bottom: 4px solid $color_lipstick_approx;
                //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
                transition: border-color .3s ease-in-out;
              }
            }
          }
        }
      }
      .nav-item-group-mobile-footer {
        display: none;
      }
    }
    &.toc {
      height: 100px;
      .nav-bar-mobile {
        height: 100px;
      }
      .nav-items {
        height: 100px;
        padding-top: 0;
        .nav-item-group-primary {
          align-items: flex-start;
          background-color: transparent;
          display: flex;
          padding: 0;
          .nav-item-primary {
            display: inline-block;
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
            &:first-child {
              margin-left: 140px;
            }
          }
          .nav-item-primary-title {
            display: inline;
            font-size: 0.9em;
            padding: 0;
            margin: 0px;
            //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
            transition: all .3s ease-in-out;
            &.active {
              color: $white;
              text-decoration: underline;
            }
          }
        }
      }
      .mobile-toc-menu-list {
        display: none;
        visibility: hidden;
      }
    }
  }
  .simple-page-header h1 {
    font-size: 2.5em;
  }
  .main-nav[data-nav-state=closed] .nav-items {
    display: flex;
  }
}
@media only screen and (min-width:1072px) {
  .main-nav .nav-bar-mobile .nav-brand .nav-logo-link {
    font-size: 1.7em;
    img {
      height: 48px;
      width: auto;
      display: inline-block;
      margin-right: 10px;
      margin-top: -3px;
    }
  }
  .footer .footer-top-row .footer-column {
    min-width: 144px;
  }
}
@media only screen and (max-width:1023px) {
  .main-nav {
    .nav-items{
      width: 100%;
      right: 0px;
    }
    .nav-bar-mobile .nav-brand .nav-logo-link {
      font-size: 1.4em;
    }
  }
}
body {
  background-color: $white;
  margin: 0; 
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  color: #1e293b;
}
html {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  height: 100%;
  &:after {
    content: "";
    display: block;
    opacity: 0;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all .5s ease-in-out;
  }
}
@media only screen and (max-width:767px) {
  .footer{
    .footer-top-row {
      .contact-link {
        font-size: 1.1em;
        margin: 18px 0px 10px 0px;
        color: $black;
      }
    }
  }
}
.main-nav[data-nav-state=opened] .nav-bar-mobile .nav-mobile-toggle button {
  .close {
    display: block;
  }
  .open {
    display: none;
  }
}
.breadcrumbs{
  span{
    font-size: 0.875em;
    vertical-align: middle;
    padding: 0 5px;
  }
}
.cart-panel{
  position: fixed;
  top: 70px;
  right: -25em;
  height: calc(100vh - 70px);
  min-width: 17em;
  max-width: 25em;
  z-index: 2000;
  background-color: #fff;
  overflow: auto;
  transition: all 0.3s ease;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  &.show{
      right: 0;
  }
  ul.list-menu{
      margin: 10px 10px 0px 10px;
      li{
          width: 100%;
          text-align: left;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          border: 1px solid #ccc;
          margin: 0 0 10px 0;
          font-size: 0.75rem;
          background: #fff;
          padding: 0px 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:hover, &.active{
              background: #f0f5f9;
              color: #1155cc;
              border: 1px solid #1155cc;
          }
      }
  }
}
.cart-panel-mobile{
  display: none;
}
.search-panel{
  background: url(./assets/images/bg-pattern.png) repeat #222;
  border-bottom: 1px solid #eee;
  position: fixed;
  width: 100%;
  top: -164px;
  left: 0px;
  transition: all 0.3s ease;
  z-index: 9;
  &.show{
    top: 70px;
  }
}
.mat-drawer-content{
  overflow: overlay !important;
  scrollbar-gutter: stable;
}
::-webkit-scrollbar {
  width: 8px;
  border: none;
  background: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  border: none;
}

::-webkit-scrollbar-thumb {
  background-color: #646464;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.card-price {
  margin-bottom: 0px;
  color: #af0000;
  font-size: 1.1em !important;
  line-height: 30px;
  .crossed{
    text-decoration: line-through;
    color: #666;
    font-size: 0.8em;
  }
}

.book-button {
  padding: 4px 10px;
  background-color: #fff;
  color: #000;
  border: 1px solid #af0000;
  border-radius: 0px;
  cursor: pointer;
  font-size: 0.875em;
  transition: all 0.1s ease;
  &.warn {
    color: #fff;
    background: #af0000;
  }
  &:hover{
    border: 1px solid #000;
    background-color: #ffd966;
    color: #000;
  }
  .mat-icon, .material-symbols-sharp {
    line-height: 20px !important;
    vertical-align: bottom !important;
    height: 20px !important;
    font-size: 16px !important;
    min-height: 20px !important;
    padding-right: 3px;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.72) !important;
}

app-title-content {
  a {
    background-image: linear-gradient(180deg, transparent 96%, #1155cc 0);
    background-repeat: no-repeat;
    background-size: 0 100%;
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    color: $color_elf_green_approx;
    line-height: 1.4;
    &:hover, &:focus{
      background-size: 100% 100%;
      color: #1155cc;
      text-decoration: none;
    }
  }
}
@media only screen and (max-width:991px) {
  .simple-page-container{
    padding-top: 60px;
  }
  .simple-page-header{
    h1{
      font-size: 1.2em;
      line-height: normal;
    }
  }
  .search-panel{
    top: -280px;
    &.show{
      top: 60px;
    }
  }
  .cart-panel{
    display: none;
  }
  .cart-panel-mobile{
    position: fixed;
    z-index: 2000;
    top: 60px;
    width: 100%;
    max-width: 100%;
    right: -100%;
    height: calc(100vh - 60px);
    background: rgba(0, 0, 0, 0.42);
    border: none;
    transition: none;
    display: block;
    .cart{
      position: fixed;
      top: 60px;
      right: -250px;
      height: calc(100vh - 60px);
      min-width: 250px;
      max-width: 250px;
      z-index: 2000;
      background-color: #fff;
      overflow: auto;
      transition: all 0.3s ease;
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
    }
    &.show{
      right: 0;
      .cart{
        right: 0;
      }
    }
  }
  .footer{
    .footer-container{
      max-width: 100% !important;
    }
    .footer-top-row {
      .footer-logo-div {
        margin-bottom: 15px;
      }
      ul{
        li{
          text-align: left;
          padding: 0px;
        }
      }
    }
    .footer-bottom-row {
      justify-content: start;
      flex-direction: column;
    }
    .footer-social-links-wrapper {
      align-self: flex-end;
      order: 2;
      width: 100%;
      font-size: 0.8em;
      line-height: 18px;
    }
    .footer-secondary-links-wrapper{
      a{
        display: block;
        margin-left: 0px;
        padding-left: 0px;
        border-left: none;
        margin-bottom: 12px;
      }
    }
  }
}
@import "./assets/css/portal/components.scss";
@import "./assets/css/portal/components/form-elements/input.scss";
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.min.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.min.css';

@import "./assets/css/portal/global/layout.scss";

@import "./assets/css/app-components/login.component.scss";

html {
  body{
    .simple-page-container{
      --mat-form-field-container-text-line-height: 34px;
      .mat-mdc-form-field.mat-form-field-appearance-outline:not(.search-input):not(.form-class) {
        .mat-mdc-form-field-infix {
          min-height: 48px;
        }
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            .mat-mdc-floating-label {
              top: 25px;
            }
          }
        }
      }
      .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
        padding: 0px;
      }
    }
    .mat-mdc-form-field-error-wrapper{
      padding: 0px !important;
    }
    .mat-mdc-form-field-error {
      display: block !important;
    } 
    mat-error{
      font-size: 12px;
      line-height: normal;
      font-weight: 400;
    }
  }
}

.close-search{
  position: absolute;
  right: 20px;
  bottom: 4px;
  background: transparent !important;
  border-radius: 100% !important;
  width: 20px !important;
  height: 20px !important;
  padding: 0px;
  color: #eee;
  border: none;
  box-shadow: none;
  &:hover, &:focus{
      border: transparent !important;
      background: #f1f3f4 !important;
      color: #000;
      .mat-button-focus-overlay{
          opacity: 0 !important;
          background-color: transparent !important;
      }
  }
  .mat-icon, .material-symbols-sharp {
      line-height: 20px !important;
      vertical-align: text-bottom !important;
      height: 20px !important;
      font-size: 16px !important;
      min-height: 20px !important;
  }
}


.drag-box.cdk-drag{
  position: relative;
  padding: 0px 0px 0px 0px;
  &:before{
      content: "\e945";
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 20px;
      background: rgba(161, 194, 250, 0.1882352941);
      cursor: grab;
      z-index: 2;
      font-family: "Material Icons";
      font-size: 18px;
      line-height: 28px;
      color: #cf5050;
  }
  &.cdk-drag-disabled{
      padding: 0px;
      &:before{
          cursor: default;
          opacity: 0.6;
          color: #666;
          display: none;
      }
  }
  &.input-drag{
    &:before{
      height: 38px;
      line-height: 38px;
    }
  }
}

.inline-form .text-right {
  float: left;
  margin: 0.3em 1em;
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}

.inline-form .row {
  float: left;
}

.home-icon{
  img{
    height: 48px;
    width: auto !important;
    margin: 0 auto;
    display: block;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.header-cart{
  position: relative;
}
.cart-items {
  background: #af0000;
  color: #fff;
  font-size: 0.85em;
  height: 16px;
  width: 16px;
  line-height: 19px;
  border-radius: 100%;
  text-align: center;
  font-family: 'Courier Prime', courier;
  font-weight: 400;
  position: absolute;
  top: 16px;
  right: -5px;
  border: none;
  display: block;
  letter-spacing: 0;
}

.reverse-checkbox{
  .mat-mdc-checkbox{
    margin-right: 0px;
    .mdc-form-field{
      flex-direction: row-reverse;
      >label {
        padding-left: 0px;
        padding-right: 4px;
      }
    }
  }
}

.gray-bg{
  background: #f5f5f5;
}

.small-icon.mat-mdc-icon-button{
  .mat-icon, .material-symbols-sharp{
      width: 18px !important;
      min-width: 18px !important;
      height: 18px !important;
      min-height: 18px !important;
      font-size: 18px !important;
      line-height: 18px !important;
  }
}