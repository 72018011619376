
html{
  --mat-slider-value-indicator-width:auto;
  --mat-slider-value-indicator-height:32px;
  --mat-slider-value-indicator-caret-display:block;
  --mat-slider-value-indicator-border-radius:4px;
  --mat-slider-value-indicator-padding:0 12px;
  --mat-slider-value-indicator-text-transform:none;
  --mat-slider-value-indicator-container-transform:translateX(-50%);
  --mdc-slider-active-track-height:6px;
  --mdc-slider-active-track-shape:9999px;
  --mdc-slider-handle-height:20px;
  --mdc-slider-handle-shape:50%;
  --mdc-slider-handle-width:20px;
  --mdc-slider-inactive-track-height:4px;
  --mdc-slider-inactive-track-shape:9999px;
  --mdc-slider-with-overlap-handle-outline-width:1px;
  --mdc-slider-with-tick-marks-active-container-opacity:0.6;
  --mdc-slider-with-tick-marks-container-shape:50%;
  --mdc-slider-with-tick-marks-container-size:2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity:0.6;
  --mdc-slider-handle-color: #0b5394;
  --mdc-slider-focus-handle-color:#0b5394;
  --mdc-slider-hover-handle-color:#0b5394;
  --mdc-slider-active-track-color:#0b5394;
  --mdc-slider-inactive-track-color:#0b5394;
  --mdc-slider-with-tick-marks-inactive-container-color:#0b5394;
  --mdc-slider-with-tick-marks-active-container-color:white;
  --mdc-slider-disabled-active-track-color:#666;
  --mdc-slider-disabled-handle-color:#666;
  --mdc-slider-disabled-inactive-track-color:#666;
  --mdc-slider-label-container-color:#000;
  --mdc-slider-label-label-text-color:#fff;
  --mdc-slider-with-overlap-handle-outline-color:#fff;
  --mdc-slider-with-tick-marks-disabled-container-color:#666;
  --mdc-slider-handle-elevation:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color:#0b5394;
  --mat-slider-hover-state-layer-color:rgba(63, 81, 181, 0.05);
  --mat-slider-focus-state-layer-color:rgba(63, 81, 181, 0.2);
  --mat-slider-value-indicator-opacity:0.6;
  --mdc-slider-label-label-text-font: Karla, Roboto, sans-serif;
  --mdc-slider-label-label-text-size:14px;
  --mdc-slider-label-label-text-line-height:22px;
  --mdc-slider-label-label-text-tracking:0.0071428571em;
  --mdc-slider-label-label-text-weight:500;
  .mat-mdc-slider{
    display:block;
    width: calc(100% - 16px);
  }
}