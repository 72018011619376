
html{
    --mdc-tab-indicator-active-indicator-height:3px;
    --mdc-tab-indicator-active-indicator-shape:0;
    --mdc-secondary-navigation-tab-container-height:48px;
    --mat-tab-header-divider-color:transparent;
    --mat-tab-header-divider-height:0;
    .mat-mdc-tab-group{
        --mdc-tab-indicator-active-indicator-color:#1155cc;
        --mat-tab-header-disabled-ripple-color:rgba(0, 0, 0, 0.38);
        --mat-tab-header-active-ripple-color:rgba(0, 0, 0, 0.38);
        --mat-tab-header-inactive-ripple-color:rgba(0, 0, 0, 0.38);
        .mat-mdc-tab-header{
            --mat-tab-header-active-focus-indicator-color:#1155cc;
            --mdc-secondary-navigation-tab-container-height:48px;
            --mat-tab-header-label-text-font: Karla, sans-serif;
            --mat-tab-header-label-text-size:inherit;
            --mat-tab-header-label-text-tracking:normal;
            --mat-tab-header-label-text-line-height:36px;
            --mat-tab-header-label-text-weight:500;
            .mat-mdc-tab-label-container{
                padding: 0;
                .mat-mdc-tab-list{
                    .mat-mdc-tab-labels{
                        .mat-mdc-tab{
                            height: 30px !important;
                            background: rgba(0, 0, 0, 0.04) !important;
                            border-radius: 0px;
                            flex-grow: 0;
                            margin: 0px 5px 0 0;
                            .mdc-tab__text-label{
                                color: #1155cc !important;
                            }
                            &.mdc-tab--active {
                                .mdc-tab__text-label{
                                    color: #000 !important;
                                }
                            }
                            .mat-mdc-tab-ripple{
                                border-radius: 0px;
                            }
                            &:hover{
                                .mdc-tab__ripple::before {
                                    border-radius: 0px;
                                }
                            }
                            &+.mat-mdc-tab {
                                margin-left: 0px;
                            }
                        }
                    }
                }
            }
        }
        .mat-mdc-tab-body-content{
            padding: 15px 0px;
            app-form {
                width: calc(100% - 15px);
                display: block;
            }
        }
    }
}