.mat-drawer-container{
    height: calc(100% - 88px);
}

.mat-drawer{
    min-width: 250px;
    background-color: #fff !important;
    border-right: 1px solid #ccc !important;
    .nav-bar{
        ul{
            padding: 15px;
            margin: 0;
            li{
                list-style: none;
                margin-bottom: 6px;
                a{
                    text-decoration: none;
                    font-size: 0.96rem;
                    color: #000;
                    cursor: pointer;
                    margin-left: 0;
                    border-radius: 0;
                    background-color: #fff !important;
                    border: 1px solid #ccc !important;
                    border-left: 1px solid #ccc !important;
                    padding: 2px 10px;
                    line-height: 34px;
                    text-transform: capitalize;
                    font-size: 1em !important;
                    font-weight: 400;
                    font-variant: unset !important;
                    opacity: 1!important;
                    display: block;
                    position: relative;
                    &:before{
                        content: '';
                        width: 1px;
                        height: 100%;
                        background-color: transparent;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                    &:hover{
                        border: 1px solid #646464 !important;
                    }
                    &.active{
                        background: #fff !important;
                        border: 1px solid #646464 !important;
                        border-left: 1px solid #000 !important;
                        &::before{
                            background-color: #000;
                        }
                    }
                }
                ul{
                    padding: 0px;
                    margin: 10px 0 0 10px;
                }
                &.disabled{
                    color: #999;
                    a{
                        cursor: default;
                        color: #999;
                        &:hover{
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
.mat-drawer-content{
    z-index: 3 !important;
    overflow: overlay !important;
    scrollbar-gutter: stable;
    padding-top: 1.5em;
}
::-webkit-scrollbar {
    width: 8px;
    border: none;
    background: transparent;
}
 
::-webkit-scrollbar-track {
    box-shadow: none;
    border: none;
}
 
::-webkit-scrollbar-thumb {
  background-color: #646464;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.w-full{
    width: 100%;
}
.bordered{
    border: 1px solid #ccc;
}
.form-bg{
    background: #f0f5f9 !important;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
a.portal-link {
    position: absolute;
    top: 49px;
    left: 15px;
    z-index: 5;
}
.cdk-drag{
    position: relative;
    padding: 0px 20px 0px 0px;
    &:before{
        content: "\e945";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 20px;
        background: rgba(161, 194, 250, 0.1882352941);
        cursor: grab;
        z-index: 2;
        font-family: "Material Icons";
        font-size: 18px;
        line-height: 28px;
        color: #cf5050;
    }
    &.cdk-drag-disabled{
        padding: 0px;
        &:before{
            cursor: default;
            opacity: 0.6;
            color: #666;
            display: none;
        }
    }
}