html {
    --mdc-filled-text-field-active-indicator-height: 1px;
    --mdc-filled-text-field-focus-active-indicator-height: 2px;
    --mdc-filled-text-field-container-shape: 0px !important;
    --mdc-outlined-text-field-outline-width: 1px !important;
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-container-shape: 0px !important;

    --mdc-filled-text-field-caret-color: #2196f3;
    --mdc-filled-text-field-focus-active-indicator-color: #2196f3;
    --mdc-filled-text-field-focus-label-text-color: #2196f3;
    --mdc-filled-text-field-container-color: rgba(0, 0, 0, 0) !important;
    --mdc-filled-text-field-disabled-container-color: transparent !important;
    --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
    --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-filled-text-field-input-text-color: #000;
    --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
    --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
    --mdc-filled-text-field-error-focus-label-text-color: #cf5050;
    --mdc-filled-text-field-error-label-text-color: #cf5050;
    --mdc-filled-text-field-error-caret-color: #cf5050;
    --mdc-filled-text-field-active-indicator-color: #0000006b;
    --mdc-filled-text-field-disabled-active-indicator-color: #0000006b;
    --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
    --mdc-filled-text-field-error-active-indicator-color: #cf5050;
    --mdc-filled-text-field-error-focus-active-indicator-color: #cf5050;
    --mdc-filled-text-field-error-hover-active-indicator-color: #cf5050;
    --mdc-outlined-text-field-caret-color: #000;
    --mdc-outlined-text-field-focus-outline-color: #000;
    --mdc-outlined-text-field-focus-label-text-color: #1155cc;
    --mdc-outlined-text-field-label-text-color: #969696;
    --mdc-outlined-text-field-disabled-label-text-color: #969696;
    --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
    --mdc-outlined-text-field-disabled-input-text-color: #969696;
    --mdc-outlined-text-field-input-text-placeholder-color: #969696;
    --mdc-outlined-text-field-error-caret-color: #cf5050;
    --mdc-outlined-text-field-error-focus-label-text-color: #cf5050;
    --mdc-outlined-text-field-error-label-text-color: #cf5050;
    --mdc-outlined-text-field-outline-color: #ccc;
    --mdc-outlined-text-field-disabled-outline-color: #ccc;
    --mdc-outlined-text-field-hover-outline-color: #ccc;
    --mdc-outlined-text-field-error-focus-outline-color: #cf5050;
    --mdc-outlined-text-field-error-hover-outline-color: #cf5050;
    --mdc-outlined-text-field-error-outline-color: #cf5050;
    --mat-form-field-focus-select-arrow-color: rgba(63, 81, 181, 0.87);
    --mat-form-field-disabled-input-text-placeholder-color: #969696;
    --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
    --mat-form-field-error-text-color: #cf5050;
    --mat-form-field-select-option-text-color: inherit;
    --mat-form-field-select-disabled-option-text-color: GrayText;
    --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
    --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
    --mat-form-field-hover-state-layer-opacity: 0.04;
    --mat-form-field-focus-state-layer-opacity: 0.12;

    --mdc-filled-text-field-label-text-font: Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-filled-text-field-label-text-size: 0.95em;
    --mdc-filled-text-field-label-text-tracking: normal;
    --mdc-filled-text-field-label-text-weight: 400;
    --mdc-outlined-text-field-label-text-font: Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-outlined-text-field-label-text-size: 0.95em;
    --mdc-outlined-text-field-label-text-tracking: normal;
    --mdc-outlined-text-field-label-text-weight: 400;
    --mat-form-field-container-text-font: Karla, Roboto, "Helvetica Neue", sans-serif;
    --mat-form-field-container-text-line-height: 24px;
    --mat-form-field-container-text-size: 0.95em;
    --mat-form-field-container-text-tracking: normal;
    --mat-form-field-container-text-weight: 400;
    --mat-form-field-outlined-label-text-populated-size: 0.95em;
    --mat-form-field-subscript-text-font: Karla, Roboto, "Helvetica Neue", sans-serif;
    --mat-form-field-subscript-text-line-height: 12px;
    --mat-form-field-subscript-text-size: 11px;
    --mat-form-field-subscript-text-tracking: normal;
    --mat-form-field-subscript-text-weight: 400;

    --mat-select-panel-background-color: white;
    --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
    --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
    --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
    --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
    --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
    --mat-select-focused-arrow-color: #000;
    --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);

    --mat-select-trigger-text-font: Karla, Roboto, "Helvetica Neue", sans-serif;
    --mat-select-trigger-text-line-height: 24px;
    --mat-select-trigger-text-size: 0.95em;
    --mat-select-trigger-text-tracking: normal;
    --mat-select-trigger-text-weight: 400;

    --mat-autocomplete-background-color: white;

    body {
        .mat-mdc-form-field {
            width: 100%;
            --mat-mdc-form-field-floating-label-scale: 0.9 !important;

            &.mat-accent {
                --mdc-filled-text-field-caret-color: #000;
                --mdc-filled-text-field-focus-active-indicator-color: #000;
                --mdc-filled-text-field-focus-label-text-color: #1155cc;
                --mdc-outlined-text-field-caret-color: #000;
                --mdc-outlined-text-field-focus-outline-color: #000;
                --mdc-outlined-text-field-focus-label-text-color: #1155cc;
                --mat-form-field-focus-select-arrow-color: #000;
                --mat-select-panel-background-color: white;
                --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
                --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
                --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
                --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
                --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
                --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
                --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87)
            }

            &.mat-warn {
                --mdc-filled-text-field-caret-color: #000;
                --mdc-filled-text-field-focus-active-indicator-color: #000;
                --mdc-filled-text-field-focus-label-text-color: #cf5050;
                --mdc-outlined-text-field-caret-color: #000;
                --mdc-outlined-text-field-focus-outline-color: #000;
                --mdc-outlined-text-field-focus-label-text-color: #cf5050;
                --mat-form-field-focus-select-arrow-color: #000;
                --mat-select-panel-background-color: white;
                --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
                --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
                --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
                --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
                --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
                --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
                --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87)
            }

            .mat-mdc-form-field-infix {
                min-height: 38px
            }

            .mat-mdc-form-field-focus-overlay {
                background-color: transparent;
            }

            .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
                top: 18px;
            }

            &.mat-form-field-appearance-fill {
                .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
                    height: 28px;
                }

                .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
                    top: 18px;
                    width: auto;
                }
            }

            .mat-mdc-form-field-icon-suffix .material-symbols-sharp {
                font-size: 18px;
            }

            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
                --mat-mdc-form-field-label-transform: translateY(-1.5em) scale(var(--mat-mdc-form-field-floating-label-scale, 0.9));
                transform: var(--mat-mdc-form-field-label-transform)
            }

            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
                padding-top: 14px;
                padding-bottom: 0px;
                padding-left: 5px;
                padding-right: 5px;
            }

            .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
                padding-top: 8px;
                padding-bottom: 2px;
                padding-left: 5px;
                padding-right: 5px;

            }

            .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
            }

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-radius: 0px !important;
            }

            &.mat-form-field-appearance-outline .mdc-text-field--outlined {
                background-color: #fff;

                .mdc-notched-outline {
                    .mdc-notched-outline__notch {
                        border-right: 1px solid rgba(0, 0, 0, 0) !important;
                        width: 100% !important;
                        max-width: calc(100% - 13px);
                    }

                    .mdc-notched-outline__leading {
                        width: 1px !important;
                    }
                }
            }

            &.mat-form-field-appearance-outline.required-field.ng-invalid:not(.mat-form-field-invalid):not(.ng-valid) {
                .mdc-notched-outline {
                    .mdc-notched-outline__leading {
                        border-left-color: #cf5050 !important;
                    }
                }
            }

            .mdc-text-field {
                padding: 0px;
            }

            .mat-mdc-form-field-required-marker {
                display: none !important;
            }

            .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button {
                --mdc-icon-button-state-layer-size: 30px !important;
                padding: 0px !important;
            }
        }

        div.mat-mdc-select-panel {
            padding: 0px;
            border-radius: 0px;
            border: 1px solid #ccc;
        }

        div.mat-mdc-autocomplete-panel {
            border: 1px solid #ccc;
            padding-bottom: 0;
            padding-top: 0;
        }

        .mat-mdc-option {
            padding-left: 5px;
            padding-right: 5px;
            font-size: 0.825rem;
            min-height: 2.25em;
            height: 2.25em;
            line-height: 2.25em;
            --mat-full-pseudo-checkbox-selected-checkmark-color: #1155cc;
            --mat-full-pseudo-checkbox-unselected-icon-color: #bbb;
            &:hover:not(.mat-option-disabled),
            &:focus:not(.mat-option-disabled),
            &:hover:not(.mat-option-disabled),
            &:focus:not(.mat-option-disabled),
            &.mat-mdc-option-active,
            &.mdc-list-item--selected {
                background: rgba(148, 163, 184, .12);
            }

            &:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
                display: none;
            }

            .mat-pseudo-checkbox-full {
                margin-right: 10px;
                border-width: 1px;
                &.mat-pseudo-checkbox-checked,
                &.mat-pseudo-checkbox-indeterminate {
                    background-color: #fff;
                    color: #1155cc;
                    border-color: #bbb;
                }
            }

            .mdc-list-item__primary-text {
                white-space: nowrap !important;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .mat-mdc-list-option {
            --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
            --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
            --mdc-checkbox-selected-checkmark-color: #fff;
            --mdc-checkbox-selected-focus-icon-color: #3f51b5;
            --mdc-checkbox-selected-hover-icon-color: #3f51b5;
            --mdc-checkbox-selected-icon-color: #3f51b5;
            --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
            --mdc-checkbox-unselected-focus-icon-color: #000;
            --mdc-checkbox-unselected-hover-icon-color: #000;
            --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
            --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
            --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
            --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
            --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
            --mdc-checkbox-unselected-focus-state-layer-color: black;
            --mdc-checkbox-unselected-hover-state-layer-color: black;
            --mdc-checkbox-unselected-pressed-state-layer-color: black
        }

        textarea {
            resize: none !important;
        }

        .field-with-button {
            .mat-mdc-form-field {
                width: calc(100% - 60px) !important;
            }

            button {
                top: 5px;
            }
        }
    }

    .mat-mdc-form-field-error {
        display: none !important;
    }

    .mat-mdc-select-arrow {
        width: 20px;
        height: 11px;

        svg {
            top: 0;
        }
    }

    .required-field {
        &.ng-untouched {

            .ql-toolbar.ql-snow,
            .ql-container.ql-snow {
                border-left-color: #cf5050 !important;
            }
        }
    }

    .colorPicker {
        input {
            height: 18px !important;
            margin-bottom: 0px;
        }
    }

    .input-select {
        input {
            margin: 5px;
            width: calc(100% - 38px);
            padding: 0 2px;
        }

        button {
            margin: 4px 0px;
        }

        mat-option[value="0"] {
            display: none;
        }

        .edit-options {
            position: relative;

            mat-option {
                width: calc(100% - 38px);
            }

            button {
                position: absolute;
                right: 0;
                top: 0;
                margin: 0px;
            }
        }
    }

    .list-management-panel {
        position: relative;

        button {
            position: absolute;
            right: 0px;
            top: 0px;
            --mdc-icon-button-state-layer-size: 38px !important;

            .mat-icon,
            .material-symbols-sharp {
                height: 34px !important;
                line-height: 35px !important;
                width: 34px !important;
                font-size: 18px !important;
            }
        }

        .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
            padding: 14px 42px 0 5px;
        }
    }
}

quill-editor {
    &.content-editor {
        width: 100%;
    }

    .ql-editor {
        background-color: #fff;
    }
}

.ql-toolbar.ql-snow {
    padding: 8px 0px;

    .ql-formats {
        margin-right: 4px;
    }

    button {
        padding: 3px 2px;
        width: 24px;
    }
}

.ql-editor {
    ol {
        padding-left: 0px;

        li {
            list-style: decimal-leading-zero;
            margin-left: 25px;
            padding-left: 0px !important;
            &:before{
                content: '';
            }
        }
    }

    ul {
        padding-left: 0px;

        li {
            list-style: disc;
            margin-left: 15px;
            padding-left: 0px !important;
        }
    }
}

.ql-container.ql-snow {
    height: 150px;
}

.ql-toolbar.ql-snow {
    padding: 8px 0px;

    .ql-picker {
        font-size: 12px;
    }

    .ql-formats {
        margin-right: 4px;
    }

    button {
        padding: 3px 2px;
        width: 24px;
    }

    .ql-font {
        span[data-label="Karla"]::before {
            font-family: "Karla";
        }

        span[data-label="Lora"]::before {
            font-family: "Lora";
        }

        span[data-label="Roboto Slab"]::before {
            font-family: "Roboto Slab";
        }

        span[data-label="Oswald"]::before {
            font-family: "Oswald";
        }

        span[data-label="Courgette"]::before {
            font-family: "Courgette";
        }

        span[data-label="Roboto Mono"]::before {
            font-family: "Roboto Mono";
        }
    }
}

.ql-editor {
    ol {
        padding-left: 0px;

        li {
            list-style: decimal-leading-zero;
            margin-left: 22px;
            padding-left: 0px !important;
        }
    }

    ul {
        padding-left: 0px;

        li {
            list-style: disc;
            margin-left: 15px;
            padding-left: 0px !important;
        }
    }
}

.ql-font-Karla {
    font-family: "Karla";
}

.ql-font-Lora {
    font-family: "Lora";
}

.ql-font-RobotoSlab {
    font-family: "Roboto Slab";
}

.ql-font-Oswald {
    font-family: "Oswald";
}

.ql-font-Courgette {
    font-family: "Courgette";
}

.ql-font-RobotoMono {
    font-family: "Roboto Mono";
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ql-formats {
    button {
        position: relative;

        .material-symbols-sharp {
            font-size: 18px;
        }

        .small-icon {
            color: #cf5050;
            font-size: 12px;
            position: absolute;
            bottom: 0px;
            right: 0px;
            height: 12px;
            width: 12px;
        }
    }
}

html {
    .mat-mdc-standard-chip {
        --mdc-chip-container-shape-family: rounded;
        --mdc-chip-container-shape-radius: 0px;
        --mdc-chip-with-avatar-avatar-shape-family: rounded;
        --mdc-chip-with-avatar-avatar-shape-radius: 0px;
        --mdc-chip-with-avatar-avatar-size: 28px;
        --mdc-chip-with-icon-icon-size: 16px;
        --mdc-chip-outline-width: 1px;
        --mdc-chip-outline-color: #ccc;
        --mdc-chip-disabled-outline-color: #ccc;
        --mdc-chip-focus-outline-color: #000;
        --mdc-chip-hover-state-layer-opacity: 0.04;
        --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
        --mdc-chip-flat-selected-outline-width: 1px;
        --mdc-chip-selected-hover-state-layer-opacity: 0.04;
        --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
        --mdc-chip-with-icon-disabled-icon-opacity: 1;
        --mat-chip-disabled-container-opacity: 0.4;
        --mat-chip-trailing-action-opacity: 1;
        --mat-chip-trailing-action-focus-opacity: 1;
        --mat-chip-trailing-action-state-layer-color: transparent;
        --mat-chip-selected-trailing-action-state-layer-color: transparent;
        --mat-chip-trailing-action-hover-state-layer-opacity: 0;
        --mat-chip-trailing-action-focus-state-layer-opacity: 0
    }

    .mat-mdc-standard-chip {
        --mdc-chip-disabled-label-text-color: #000;
        --mdc-chip-elevated-container-color: #fff;
        --mdc-chip-elevated-selected-container-color: #f5f5f5;
        --mdc-chip-elevated-disabled-container-color: #fff;
        --mdc-chip-flat-disabled-selected-container-color: #f5f5f5;
        --mdc-chip-focus-state-layer-color: black;
        --mdc-chip-hover-state-layer-color: black;
        --mdc-chip-selected-hover-state-layer-color: black;
        --mdc-chip-focus-state-layer-opacity: 0.12;
        --mdc-chip-selected-focus-state-layer-color: black;
        --mdc-chip-selected-focus-state-layer-opacity: 0.12;
        --mdc-chip-label-text-color: #000;
        --mdc-chip-selected-label-text-color: #000;
        --mdc-chip-with-icon-icon-color: #000;
        --mdc-chip-with-icon-disabled-icon-color: #666;
        --mdc-chip-with-icon-selected-icon-color: #1155cc;
        --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #000;
        --mdc-chip-with-trailing-icon-trailing-icon-color: #cf5050;
        --mat-chip-selected-disabled-trailing-icon-color: #cf5050;
        --mat-chip-selected-trailing-icon-color: #cf5050
    }

    .mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 26px
    }

    .mat-mdc-standard-chip {
        --mdc-chip-label-text-font: Karla, sans-serif;
        --mdc-chip-label-text-line-height: 20px;
        --mdc-chip-label-text-size: 13px;
        --mdc-chip-label-text-tracking: 0.0178571429em;
        --mdc-chip-label-text-weight: 400;
        &.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary{
            padding-left: 8px;
        }
        &.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .mat-mdc-chip-remove .mat-icon {
        width: 14px;
        height: 18px;
        font-size: 14px;
        line-height: 18px;
        box-sizing: content-box;
    }
}