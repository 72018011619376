html{
    --mat-datepicker-calendar-date-selected-state-text-color:white;
    --mat-datepicker-calendar-date-selected-state-background-color:#3f51b5;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color:rgba(63, 81, 181, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color:white;
    --mat-datepicker-calendar-date-focus-state-background-color:rgba(63, 81, 181, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color:rgba(63, 81, 181, 0.3);
    --mat-datepicker-toggle-active-state-icon-color:#3f51b5;
    --mat-datepicker-calendar-date-in-range-state-background-color:rgba(63, 81, 181, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color:rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color:#a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color:#46a35e;
    --mat-datepicker-toggle-icon-color:rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-body-label-text-color:rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-period-button-icon-color:rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-navigation-button-icon-color:rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-header-divider-color:rgba(0, 0, 0, 0.12);
    --mat-datepicker-calendar-header-text-color:rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-date-today-outline-color:rgba(0, 0, 0, 0.38);
    --mat-datepicker-calendar-date-today-disabled-state-outline-color:rgba(0, 0, 0, 0.18);
    --mat-datepicker-calendar-date-text-color:rgba(0, 0, 0, 0.87);
    --mat-datepicker-calendar-date-outline-color:transparent;
    --mat-datepicker-calendar-date-disabled-state-text-color:rgba(0, 0, 0, 0.38);
    --mat-datepicker-calendar-date-preview-state-outline-color:rgba(0, 0, 0, 0.24);
    --mat-datepicker-range-input-separator-color:rgba(0, 0, 0, 0.87);
    --mat-datepicker-range-input-disabled-state-separator-color:rgba(0, 0, 0, 0.38);
    --mat-datepicker-range-input-disabled-state-text-color:rgba(0, 0, 0, 0.38);
    --mat-datepicker-calendar-container-background-color:white;
    --mat-datepicker-calendar-container-text-color:rgba(0, 0, 0, 0.87)
}
.mat-datepicker-content.mat-accent{
    --mat-datepicker-calendar-date-selected-state-text-color:white;
    --mat-datepicker-calendar-date-selected-state-background-color:#ff4081;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color:rgba(255, 64, 129, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color:white;
    --mat-datepicker-calendar-date-focus-state-background-color:rgba(255, 64, 129, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color:rgba(255, 64, 129, 0.3);
    --mat-datepicker-calendar-date-in-range-state-background-color:rgba(255, 64, 129, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color:rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color:#a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color:#46a35e
}
.mat-datepicker-content.mat-warn{
    --mat-datepicker-calendar-date-selected-state-text-color:white;
    --mat-datepicker-calendar-date-selected-state-background-color:#cf5050;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color:rgba(244, 67, 54, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color:white;
    --mat-datepicker-calendar-date-focus-state-background-color:rgba(244, 67, 54, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color:rgba(244, 67, 54, 0.3);
    --mat-datepicker-calendar-date-in-range-state-background-color:rgba(244, 67, 54, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color:rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color:#a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color:#46a35e
}
.mat-datepicker-toggle-active.mat-accent{
    --mat-datepicker-toggle-active-state-icon-color:#ff4081
}
.mat-datepicker-toggle-active.mat-warn{
    --mat-datepicker-toggle-active-state-icon-color:#cf5050
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base{
    --mdc-icon-button-state-layer-size:40px !important;
    width:var(--mdc-icon-button-state-layer-size);
    height:var(--mdc-icon-button-state-layer-size);
    padding:8px
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target{
    display:none
}
html{
    --mat-datepicker-calendar-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mat-datepicker-calendar-text-size:13px;
    --mat-datepicker-calendar-body-label-text-size:14px;
    --mat-datepicker-calendar-body-label-text-weight:500;
    --mat-datepicker-calendar-period-button-text-size:14px;
    --mat-datepicker-calendar-period-button-text-weight:500;
    --mat-datepicker-calendar-header-text-size:11px;
    --mat-datepicker-calendar-header-text-weight:400;
    .mat-datepicker-content .mat-calendar{
        border: 1px solid #ccc
    }
}