$color_success: #009845;
$white: #fff;
$black: #000;
$color_silver_approx: #ccc;
$color_placeholder: #969696;
$color_footer_bg: #dbe1ec;
$color_tab_font: #0b5394;
$color_menu_blue: #1c4587;
$color_normal: #63cb9e;
$color_optimal: #f6d469;
$color_high: #e62e2d;
$color_cell: #eee;
$color_maroon_red: #cf5050;
$color_footer_gray: #ddd;
$color_menu_gray: #646464;
$color_logo_1: #AF0000;
$color_logo_2: #292929;
$color_link: #1155cc;