
html{
    --mdc-dialog-container-elevation-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    --mdc-dialog-container-shadow-color:#1e293b;
    --mdc-dialog-container-shape:0px
}
html{
    --mdc-dialog-container-color:#eff5f9 !important;
    --mdc-dialog-subhead-color:#000 !important;
    --mdc-dialog-supporting-text-color:#000 !important;
}
html{
    --mdc-dialog-subhead-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-dialog-subhead-line-height:normal;
    --mdc-dialog-subhead-size:20px;
    --mdc-dialog-subhead-weight:500;
    --mdc-dialog-subhead-tracking:normal;
    --mdc-dialog-supporting-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-dialog-supporting-text-line-height:inherit;
    --mdc-dialog-supporting-text-size:0.875rem;
    --mdc-dialog-supporting-text-weight:400;
    --mdc-dialog-supporting-text-tracking:normal;

    .mat-mdc-dialog-surface {
        position: relative;
        border-radius: 0px !important;
        padding: 0px !important;
        background: #f0f5f9 !important;
        .mat-mdc-dialog-title{
            font-size: 1rem !important;
            background-color: #fff;
            margin: 0px;
            padding: 15px;
            border-bottom: 1px solid #ccc;
            span.material-symbols-sharp {
                font-size: 20px;
                height: 18px;
                width: 12px;
                display: block;
                &.Error{
                    color:#cf5050;
                }
                &.Success{
                    color:#009845;
                }
            }
            &:before{
                display: none;
            }
            &.cdk-drag-handle{
                cursor: grab;
            }
        }
        .mat-mdc-dialog-content{
            padding: 24px;
            max-height: 100%;
            .response-msg {
                margin-bottom: 1.5em;
                padding: 0px;
                li{
                    list-style: none;
                }
            }
        }
        .mat-mdc-dialog-actions{
            margin-bottom: 0px;
            padding: 0px;
            min-height: auto;
        }
        .close-popup{
            position: absolute;
            right: 8px;
            top: 9px;
            background: transparent !important;
            border-radius: 100% !important;
            width: 30px !important;
            height: 30px !important;
            *{
                border-radius: 100% !important;
                background-color: transparent !important;
            }
            .mat-mdc-button-ripple, .mat-mdc-button-persistent-ripple{
                background-color: transparent !important;
                *, &::before{
                    background-color: transparent !important;
                }
            }
            &:hover, &:focus{
                border: transparent !important;
                background: #f1f3f4 !important;
                .mat-button-focus-overlay{
                    opacity: 0 !important;
                    background-color: transparent !important;
                }
            }
            .mat-icon {
                line-height: 16px !important;
                vertical-align: middle !important;
                height: 16px !important;
                font-size: 20px !important;
                min-height: 16px !important;
            }
        }
    }
    .preview-dialog{
        .mat-mdc-dialog-surface{
            background-color: #fff !important;
        }
    }
    .reminders-dialog{
        .mat-mdc-dialog-content {
            max-height: none;
        }
    }
    .cart-dialog{
        .mat-mdc-dialog-content{
            .response-msg {
                li{
                    font-size: 1.2em;
                }
            }
        }
    }
    .auth-dialog .mat-mdc-dialog-content{
        padding: 24px!important;
    }
    .mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content{
        padding-top: 24px;
    }
}
html{
    --mat-bottom-sheet-container-shape:4px
}
html{
    --mat-bottom-sheet-container-text-color:rgba(0, 0, 0, 0.87);
    --mat-bottom-sheet-container-background-color:white
}
html{
    --mat-bottom-sheet-container-text-font:Roboto, sans-serif;
    --mat-bottom-sheet-container-text-line-height:20px;
    --mat-bottom-sheet-container-text-size:14px;
    --mat-bottom-sheet-container-text-tracking:0.0178571429em;
    --mat-bottom-sheet-container-text-weight:400
}

html{
    .mdc-snackbar__surface {
        padding: 0px;
        background-color: #f0f5f9 !important;
        margin-top: 95px;
        .mat-mdc-snack-bar-actions {
            width: 100%;
            display: block;
            position: relative;
            .close-popup {
                position: absolute;
                right: 5px;
                top: 9px;
                background: transparent !important;
                border-radius: 100% !important;
                width: 30px !important;
                height: 30px !important;
                *{
                    border-radius: 100% !important;
                }
                &:hover, &:focus{
                    border: transparent !important;
                    background: #f1f3f4 !important;
                    .mat-button-focus-overlay{
                        opacity: 0 !important;
                        background-color: transparent !important;
                    }
                }
                .mat-icon {
                    line-height: 16px !important;
                    vertical-align: middle !important;
                    height: 16px !important;
                    font-size: 20px !important;
                    min-height: 16px !important;
                }
            }
        }
        .mat-mdc-snack-bar-container .mdc-snackbar__label {
            padding-right: 36px;
        }
    }
}