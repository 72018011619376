
html{
    --mat-menu-container-shape:0px
}
html{
    --mat-menu-item-label-text-color:rgba(0, 0, 0, 0.87);
    --mat-menu-item-icon-color:rgba(0, 0, 0, 0.87);
    --mat-menu-item-hover-state-layer-color:#eee;
    --mat-menu-item-focus-state-layer-color:#eee;
    --mat-menu-container-color:white
}
html{
    --mat-menu-item-label-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mat-menu-item-label-text-size:0.83rem;
    --mat-menu-item-label-text-tracking:normal;
    --mat-menu-item-label-text-line-height:30px;
    --mat-menu-item-label-text-weight:400
}
html{
    --mdc-list-list-item-container-shape:0;
    --mdc-list-list-item-leading-avatar-shape:50%;
    --mdc-list-list-item-container-color:transparent;
    --mdc-list-list-item-selected-container-color:transparent;
    --mdc-list-list-item-leading-avatar-color:transparent;
    --mdc-list-list-item-leading-icon-size:30px;
    --mdc-list-list-item-leading-avatar-size:30px;
    --mdc-list-list-item-trailing-icon-size:30px;
    --mdc-list-list-item-disabled-state-layer-color:transparent;
    --mdc-list-list-item-disabled-state-layer-opacity:0;
    --mdc-list-list-item-disabled-label-text-opacity:0.38;
    --mdc-list-list-item-disabled-leading-icon-opacity:0.38;
    --mdc-list-list-item-disabled-trailing-icon-opacity:0.38
}
html{
    --mdc-list-list-item-label-text-color:rgba(0, 0, 0, 0.87);
    --mdc-list-list-item-supporting-text-color:rgba(0, 0, 0, 0.54);
    --mdc-list-list-item-leading-icon-color:rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-trailing-supporting-text-color:rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-trailing-icon-color:rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-selected-trailing-icon-color:rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-disabled-label-text-color:black;
    --mdc-list-list-item-disabled-leading-icon-color:black;
    --mdc-list-list-item-disabled-trailing-icon-color:black;
    --mdc-list-list-item-hover-label-text-color:rgba(0, 0, 0, 0.87);
    --mdc-list-list-item-hover-leading-icon-color:rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-hover-trailing-icon-color:rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-focus-label-text-color:rgba(0, 0, 0, 0.87);
    --mdc-list-list-item-hover-state-layer-color:black;
    --mdc-list-list-item-hover-state-layer-opacity:0.04;
    --mdc-list-list-item-focus-state-layer-color:black;
    --mdc-list-list-item-focus-state-layer-opacity:0.12
}
.mdc-list-item__start,.mdc-list-item__end{
    --mdc-radio-disabled-selected-icon-color:#000;
    --mdc-radio-disabled-unselected-icon-color:#000;
    --mdc-radio-unselected-hover-icon-color:#212121;
    --mdc-radio-unselected-icon-color:rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color:#3f51b5;
    --mdc-radio-selected-hover-icon-color:#3f51b5;
    --mdc-radio-selected-icon-color:#3f51b5;
    --mdc-radio-selected-pressed-icon-color:#3f51b5
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text{
    color:#3f51b5
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start{
    color:#3f51b5
}
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end{
    opacity:1
}
html{
    --mdc-list-list-item-one-line-container-height:30px;
    --mdc-list-list-item-two-line-container-height:60px;
    --mdc-list-list-item-three-line-container-height:88px
}
.mdc-list-item__start,.mdc-list-item__end{
    --mdc-radio-state-layer-size:30px
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line{
    height:56px
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines,.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines,.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines{
    height:72px
}
html{
    --mdc-list-list-item-label-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-list-list-item-label-text-line-height:24px;
    --mdc-list-list-item-label-text-size:16px;
    --mdc-list-list-item-label-text-tracking:normal;
    --mdc-list-list-item-label-text-weight:400;
    --mdc-list-list-item-supporting-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-list-list-item-supporting-text-line-height:20px;
    --mdc-list-list-item-supporting-text-size:14px;
    --mdc-list-list-item-supporting-text-tracking:normal;
    --mdc-list-list-item-supporting-text-weight:400;
    --mdc-list-list-item-trailing-supporting-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-list-list-item-trailing-supporting-text-line-height:20px;
    --mdc-list-list-item-trailing-supporting-text-size:12px;
    --mdc-list-list-item-trailing-supporting-text-tracking:normal;
    --mdc-list-list-item-trailing-supporting-text-weight:400
}
.mdc-list-group__subheader{
    font:400 16px/28px Karla, Roboto, "Helvetica Neue", sans-serif;
    letter-spacing:normal
}
body{
    .mat-mdc-menu-panel {
        min-width: 110px !important;
        border-radius: 0px;
        .mat-mdc-menu-content {
            padding: 0px;
            border: 1px solid #ccc;
            .mat-mdc-menu-item {
                height: 30px;
                min-height: 30px;
                padding: 0 5px;
                font-size: 0.83rem;
            }
        }
        &.labList{
          min-height: auto;
          min-width: 13rem !important;
          overflow: hidden;
          .mat-mdc-menu-item {
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
              font-size: 0.75rem;
          }
        }
    }
    .mat-mdc-menu-panel.user-menu {
        width: 200px;
        .mat-mdc-menu-content {
            padding: 5px 0;
            .mat-mdc-menu-item {
                padding: 0 10px;
                .mat-icon, .material-symbols-sharp {
                    font-size: 18px;
                    margin-right: 5px;
                    vertical-align: text-top;
                }
            }
        }
        &.notification-menu{
            width: 130px;
        }
    }
}