
html{
    --mat-expansion-container-shape:0px
}
html{
    --mat-expansion-container-background-color:white;
    --mat-expansion-container-text-color:rgba(0, 0, 0, 0.87);
    --mat-expansion-actions-divider-color:rgba(0, 0, 0, 0.12);
    --mat-expansion-header-hover-state-layer-color:rgba(0, 0, 0, 0.04);
    --mat-expansion-header-focus-state-layer-color:rgba(0, 0, 0, 0.04);
    --mat-expansion-header-disabled-state-text-color:rgba(0, 0, 0, 0.26);
    --mat-expansion-header-text-color:rgba(0, 0, 0, 0.87);
    --mat-expansion-header-description-color:#000;
    --mat-expansion-header-indicator-color:#1155cc;
}
html{
    --mat-expansion-header-collapsed-state-height:48px;
    --mat-expansion-header-expanded-state-height:64px
}
html{
    --mat-expansion-header-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mat-expansion-header-text-size:14px;
    --mat-expansion-header-text-weight:500;
    --mat-expansion-header-text-line-height:inherit;
    --mat-expansion-header-text-tracking:inherit;
    --mat-expansion-container-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mat-expansion-container-text-line-height:20px;
    --mat-expansion-container-text-size:14px;
    --mat-expansion-container-text-tracking:normal;
    --mat-expansion-container-text-weight:400
}
body{
    .mat-expansion-panel{
        margin-bottom: 15px;
        .mat-expansion-panel-header {
            border-radius: 0px;
            height: 34px;
            padding: 0 10px;
            font-size: 0.83rem;
            .mat-expansion-panel-header-title{
                color: #1155cc;
                font-size: 0.83rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }
            &:not([aria-disabled=true]):hover{
                background-color: #f0f5f9 !important;
                .mat-expansion-panel-header-title{
                    text-decoration: underline;
                }
            }
            &.mat-expanded {
                background: #f0f5f9;
                color: #1155cc;
                .mat-expansion-panel-header-title{
                    color: #1155cc;
                }
                .mat-expansion-indicator:after{
                    border-color: #1155cc !important;
                }
                &:not([aria-disabled=true]):hover{
                    background: #f0f5f9 !important;
                    .mat-expansion-panel-header-title{
                        text-decoration: underline;
                    }
                }
            }
        }
        .mat-expansion-panel-body {
            padding: 15px 0px;
            .block-list li .block-details {
                font-size: 0.8em;
                float: none;
                width: 100%;
                border: none;
            }
        }
        &.mat-expansion-panel-spacing{
            margin-bottom: 15px;
        }
    }
}