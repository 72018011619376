
html{
    --mdc-linear-progress-active-indicator-height:4px;
    --mdc-linear-progress-track-height:4px;
    --mdc-linear-progress-track-shape:0
}
.mat-mdc-progress-bar{
    --mdc-linear-progress-active-indicator-color:#3f51b5;
    --mdc-linear-progress-track-color:rgba(63, 81, 181, 0.25)
}
@keyframes mdc-linear-progress-buffering{
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots{
    background-color:rgba(63, 81, 181, 0.25);
    background-color:var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25))
}
@media(forced-colors: active){
    .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots{
        background-color:ButtonBorder
    }
}
@media all and (-ms-high-contrast: none),(-ms-high-contrast: active){
    .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots{
        background-color:rgba(0,0,0,0);
        background-image:url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(63, 81, 181, 0.25)'/%3E%3C/svg%3E")
    }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar{
    background-color:rgba(63, 81, 181, 0.25);
    background-color:var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25))
}
.mat-mdc-progress-bar.mat-accent{
    --mdc-linear-progress-active-indicator-color:#ff4081;
    --mdc-linear-progress-track-color:rgba(255, 64, 129, 0.25)
}
@keyframes mdc-linear-progress-buffering{
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots{
    background-color:rgba(255, 64, 129, 0.25);
    background-color:var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25))
}
@media(forced-colors: active){
    .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots{
        background-color:ButtonBorder
    }
}
@media all and (-ms-high-contrast: none),(-ms-high-contrast: active){
    .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots{
        background-color:rgba(0,0,0,0);
        background-image:url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 64, 129, 0.25)'/%3E%3C/svg%3E")
    }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar{
    background-color:rgba(255, 64, 129, 0.25);
    background-color:var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25))
}
.mat-mdc-progress-bar.mat-warn{
    --mdc-linear-progress-active-indicator-color:#cf5050;
    --mdc-linear-progress-track-color:rgba(244, 67, 54, 0.25)
}
@keyframes mdc-linear-progress-buffering{
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots{
    background-color:rgba(244, 67, 54, 0.25);
    background-color:var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25))
}
@media(forced-colors: active){
    .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots{
        background-color:ButtonBorder
    }
}
@media all and (-ms-high-contrast: none),(-ms-high-contrast: active){
    .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots{
        background-color:rgba(0,0,0,0);
        background-image:url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E")
    }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar{
    background-color:rgba(244, 67, 54, 0.25);
    background-color:var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25))
}

html{
    --mdc-circular-progress-active-indicator-width:4px;
    --mdc-circular-progress-size:48px
}
html{
    --mdc-circular-progress-active-indicator-color:#3f51b5
}