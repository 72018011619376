.checklist-box{
  border: 1px solid #000;
  height: 275px;
  overflow: auto;
  background: #fff;
  .list-box {
    border: 1px solid #ccc;
    margin: 5px;
    padding: 3px 5px;
    font-size: 0.875em;
    cursor: pointer;
    position: relative;
    .mat-icon{
      position: absolute;
      right: -2px;
      top: 4px;
      font-size: 1.3em;
      color: #1a73e8;
      opacity: 0; 
    }
    &.selected{
      border: 1px solid #1a73e8;
      .mat-icon{
        opacity: 1;
      }
    }
  }
  .item-box {
    border: 1px solid #ccc;
    margin: 5px;
    padding: 3px 5px;
    font-size: 0.875em;
    cursor: pointer;
    position: relative;
    .mat-icon{
      position: absolute;
      right: -2px;
      top: 4px;
      font-size: 1.3em;
      color: #cf5050;
    }
    .item-price{
      position: absolute;
      right: 5px;
      top: 4px;
    }
  }
  .mat-mdc-checkbox {
      margin: 5px 5px 0;
      width: calc(100% - 10px);
      label {
        margin: 0px;
        font-size: 0.875em;
        &.mat-checkbox-layout {
          margin: 0px;
        }
      }
  }
}