.list-management-dialog {
  .mat-mdc-dialog-surface .mat-mdc-dialog-content {
    padding: 7px !important;
    .font-small > div:first-child{
      text-align: center !important;
    }
  }
  .ag-theme-quartz {
    max-height: 332px;
  }
  
  .ag-header {
    height: 30px !important;
    min-height: 30px !important;
    .ag-header-row .ag-cell-label-container:after {
      display: none;
    }
  }

  .ag-floating-top-container [col-id="archive"] button {
    display: none;
  }

  .ag-floating-top-container [col-id="value"]{
    &:before {
      content: 'Enter list item here';
    }
  }

  .mat-mdc-icon-button .mat-icon, .mat-mdc-icon-button .material-symbols-sharp {
    font-size: 20px !important;
  }
  
  button{
    margin-top: 0px !important;
  }
  
  .ag-cell {
    line-height: 30px !important;
    .ag-cell-wrapper {
      height: 100%;
    }
    .ag-drag-handle {
      height: 100%;
      line-height: 100%;
    }
  }

  .ag-cell-inline-editing .ag-cell-editor input {
    min-height: 100% !important;
    padding-left: 0px !important;
  }

  .font-small .mdc-fab--extended.mat-accent .mat-icon, .font-small .mdc-fab--extended.mat-accent .material-symbols-sharpr {
    font-size: 0.75rem !important;
  }

  .grid{
    padding: 0px;
    .data-grid-select-columns{
      display: none;
    }
  }
}