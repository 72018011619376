@import "../portal/colors.scss";
@import "../portal/fonts.scss";


body{
  .login-container{
    width: 340px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 0px;
    margin: 140px auto 80px auto;
    background: $white;
    h1 {
        font-size: 28px !important;
        text-align: center;
    }
    .mat-form-field{
        width: 100%;
    }
    .response-msg{
        font-size: 13px;
        min-height: 15px;
        display: block;
        &.success{
            color: $color_success
        }
        &.fail{
            color: $color_maroon_red;
        }
    }
    .btn-container{
        text-align: right;
    }
  }
  .login-dialog{
    font-size: 0.875rem;
    overflow: overlay;
    .login-container{
      width: 100%;
      padding: 1.5em;
      margin: auto;
      background: $white;
      a{
        color: #1155cc;
        &:hover{
            color: #1155cc;
        }
        &.bordered-link{
            border-bottom: 1px solid #1155cc;
            &:hover{
                text-decoration: none;
            }
        }
        &:not([href]):not([class]){
            color: #1155cc;
            cursor: pointer;
        }
        &.hover-black{
            &:hover{
                color: #000;
            }
            &.bordered-link{
                &:hover{
                    border-bottom: 1px solid #000;
                }
            }
        }
      }
      h1 {
          font-size: 28px !important;
          text-align: center;
      }
      .mat-form-field{
          width: 100%;
      }
      .response-msg{
          font-size: 13px;
          min-height: 15px;
          display: block;
          &.success{
              color: $color_success
          }
          &.fail{
              color: $color_maroon_red;
          }
      }
      .btn-container{
          text-align: right;
      }
    }
    .tracking-tight {
      letter-spacing: -.025em !important;
    }

    .leading-tight {
        line-height: 1.25 !important;
    }
    .text-2xl {
        font-size: 1.25rem !important;
    }
    .mdc-dialog__surface {
      position: relative;
      border-radius: 0px !important;
      padding: 0px !important;
      background: #f0f5f9 !important;
      .mat-mdc-dialog-title{
          font-size: 1rem !important;
          background-color: #fff;
          margin: 0px;
          padding: 0px;
          border-bottom: none;
          .mat-icon {
              line-height: 17px;
              vertical-align: middle;
              height: 16px;
              font-size: 20px;
              min-height: 16px;
          }
      }
      .mat-mdc-dialog-content{
        height: 100%;
        overflow: overlay;
        max-height: 85vh !important; 
        padding: 0px;
        .nav-brand {
          display: block;
          font-size: 32px;
          text-decoration: none;
          color: #292929 !important;
          letter-spacing: 0.01em;
          border-bottom: 1px solid #444;
          margin-bottom: 10px;
          span {
            color: #AF0000 !important;
          }
          img {
              height: 32px;
              width: auto;
              display: inline-block;
              margin-right: 10px;
              margin-top: -3px;
          }
        }
      }
      .close-popup{
          position: absolute;
          right: 5px;
          top: 5px;
          .mat-mdc-button-ripple, .mat-mdc-button-persistent-ripple{
            background-color: transparent !important;
            *, &::before{
                background-color: transparent !important;
            }
          }
          &:hover, &:focus{
              border: 1px solid #dc2626;
              background: #f1f3f4 !important;
              .mat-button-focus-overlay{
                  opacity: 0 !important;
                  background-color: transparent !important;
              }
          }
      }
    }
  }
}

.home-icon{
  img{
    height: 48px;
    width: auto;
    margin: 0 auto;
    display: block;
  }
}
.gray-strip{
  background-color: #f0f5f9;
}
.nav-brand {
  text-align: center;
  display: block;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  .nav-logo-link {
      display: block;
      font-family: $font_logo;
      font-size: 32px;
      text-decoration: none;
      color: $color_logo_2 !important;
      letter-spacing: 0.01em;
      line-height: 36px;
      padding: 15px 0;
      span {
        color: $color_logo_1;
      }
  }
}

.w-full{
  width: 100% !important;
}

.mdc-button{
  min-width: none;
}

ul.response-msg{
  li{
      font-size: 0.8rem;
  }
  &.Error{
      li{
          color: #AF0000;
      }
  }
}

.cursor-pointer{
  cursor: pointer;
}

html {
  body{
    .mat-mdc-form-field.mat-form-field-appearance-fill.form-class{
      .mat-mdc-text-field-wrapper{
        .mat-mdc-form-field-flex{
          .mat-mdc-floating-label{
            top: 25px;
            &.mdc-floating-label--float-above{
              top: 18px;
            }
          }
        }
        &.mdc-text-field--filled {
          .mdc-floating-label--float-above{
            transform: translateY(-106%) scale(0.9);
          }
        }
      }
    }
  }    
}