body{
    .mat-mdc-button{
        --mdc-text-button-label-text-color:#000;
        --mdc-text-button-disabled-label-text-color:#666;
        --mat-text-button-state-layer-color:#000;
        --mat-text-button-ripple-color:rgba(0, 0, 0, 0.1);
        --mat-text-button-hover-state-layer-opacity:0.04;
        --mat-text-button-focus-state-layer-opacity:0.12;
        --mat-text-button-pressed-state-layer-opacity:0.12;
        --mdc-text-button-container-height: 28px !important;
        border-radius: 0px !important;
        .mat-mdc-button-touch-target{
            height: 100%;
            width: 100%;
        }
    }
    .mat-mdc-button.mat-primary{
        --mdc-text-button-label-text-color:#1c4587;
        --mat-text-button-state-layer-color:#1c4587;
        --mat-text-button-ripple-color:rgba(63, 81, 181, 0.1)
    }
    .mat-mdc-button.mat-accent{
        --mdc-text-button-label-text-color:#000 !important;
        --mat-text-button-state-layer-color:#d0e1ff !important;
        --mat-text-button-ripple-color:rgb(207, 226, 243, 0.1) !important
    }
    .mat-mdc-button.mat-warn{
        --mdc-text-button-label-text-color:#af0000 !important;
        --mat-text-button-state-layer-color:#d0e1ff !important;
        --mat-text-button-ripple-color:rgba(207, 80, 80, 0.1) !important
    }
    .mat-mdc-unelevated-button{
        --mdc-filled-button-container-color:transparent;
        --mdc-filled-button-label-text-color:#1c4587;
        --mdc-filled-button-disabled-container-color:#eee;
        --mdc-filled-button-disabled-label-text-color:#999;
        --mat-filled-button-state-layer-color:#000;
        --mat-filled-button-ripple-color:rgba(0, 0, 0, 0.1);
        --mat-filled-button-hover-state-layer-opacity:0.04;
        --mat-filled-button-focus-state-layer-opacity:0.12;
        --mat-filled-button-pressed-state-layer-opacity:0.12;
        --mdc-filled-button-container-height: 28px !important;
        font-variant: unset;
        font-size: 1em;
        border-radius: 0px !important;
        border: none;
        .mat-mdc-button-touch-target{
            height: 100%;
            width: 100%;
        }
    }
    .mat-mdc-unelevated-button.mat-primary{
        --mdc-filled-button-container-color:#1c4587;
        --mdc-filled-button-label-text-color:#fff;
        --mat-filled-button-state-layer-color:#1155cc;
        --mat-filled-button-ripple-color:rgba(255, 255, 255, 0.1)
    }
    .mat-mdc-unelevated-button.mat-accent{
        --mdc-filled-button-container-color:#1155cc  !important;
        --mdc-filled-button-label-text-color:#fff !important;
        --mat-filled-button-state-layer-color:#1c4587;
        --mat-filled-button-ripple-color:rgba(255, 255, 255, 0.1)
    }
    .mat-mdc-unelevated-button.mat-warn{
        --mdc-filled-button-container-color:#fff !important;
        --mdc-filled-button-label-text-color:#af0000;
        --mat-filled-button-state-layer-color:#ffd966;
        --mat-filled-button-ripple-color:rgba(255, 255, 255, 0.1)
    }
    .mat-mdc-raised-button{
        --mdc-protected-button-container-color:white;
        --mdc-protected-button-label-text-color:#000;
        --mdc-protected-button-disabled-container-color:#fff;
        --mdc-protected-button-disabled-label-text-color:#666;
        --mat-protected-button-state-layer-color:#000;
        --mat-protected-button-ripple-color:rgba(0, 0, 0, 0.1);
        --mat-protected-button-hover-state-layer-opacity:0.04;
        --mat-protected-button-focus-state-layer-opacity:0.12;
        --mat-protected-button-pressed-state-layer-opacity:0.12;
        box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        --mdc-protected-button-container-height: 28px !important;
        font-variant: unset;
        font-size: 1em;
        border-radius: 0px !important;
        .mat-mdc-button-touch-target{
            height: 100%;
            width: 100%;
        }
    }
    .mat-mdc-raised-button.mat-primary{
        --mdc-protected-button-container-color:#1c4587;
        --mdc-protected-button-label-text-color:#fff;
        --mat-protected-button-state-layer-color:#115500;
        --mat-protected-button-ripple-color:rgba(255, 255, 255, 0.1)
    }
    .mat-mdc-raised-button.mat-accent{
        --mdc-protected-button-container-color:#1155cc !important;
        --mdc-protected-button-label-text-color: #fff !important;
        --mat-protected-button-state-layer-color:#1c4587;
        --mat-protected-button-ripple-color:rgba(255, 255, 255, 0.1)
    }
    .mat-mdc-raised-button.mat-warn{
        --mdc-protected-button-container-color:#fff !important;
        --mdc-protected-button-label-text-color:#af0000;
        --mat-protected-button-state-layer-color:#ffd966;
        --mat-protected-button-ripple-color:rgba(255, 255, 255, 0.1)
    }
    .mat-mdc-raised-button:hover,.mat-mdc-raised-button:focus{
        box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
    }
    .mat-mdc-raised-button:active,.mat-mdc-raised-button:focus:active{
        box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
    }
    .mat-mdc-outlined-button{
        --mdc-outlined-button-disabled-outline-color:#666;
        --mdc-outlined-button-disabled-label-text-color:#666;
        --mdc-outlined-button-label-text-color:#000;
        --mdc-outlined-button-outline-color:#000;
        --mat-outlined-button-state-layer-color:#000;
        --mat-outlined-button-ripple-color:rgba(0, 0, 0, 0.1);
        --mat-outlined-button-hover-state-layer-opacity:0.04;
        --mat-outlined-button-focus-state-layer-opacity:0.12;
        --mat-outlined-button-pressed-state-layer-opacity:0.12;
        --mdc-outlined-button-container-height: 28px !important;
        font-variant: unset;
        font-size: 1em;
        border-radius: 0px !important;
        .mat-mdc-button-touch-target{
            height: 100%;
            width: 100%;
        }
    }
    .mat-mdc-outlined-button.mat-primary{
        --mdc-outlined-button-label-text-color:#1c4587;
        --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
        --mat-outlined-button-state-layer-color:#1c4587;
        --mat-outlined-button-ripple-color:rgba(63, 81, 181, 0.1)
    }
    .mat-mdc-outlined-button.mat-accent{
        --mdc-outlined-button-label-text-color:#000 !important;
        --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
        --mat-outlined-button-state-layer-color:#1c4587 !important;
        --mat-outlined-button-ripple-color:rgb(207, 226, 243, 0.1) !important
    }
    .mat-mdc-outlined-button.mat-warn{
        --mdc-outlined-button-label-text-color:#af0000 !important;
        --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
        --mat-outlined-button-state-layer-color:#af0000 !important;
        --mat-outlined-button-ripple-color:rgba(244, 67, 54, 0.1)
    }
    .mat-mdc-button{
        --mdc-text-button-container-height:28px
    }
    .mat-mdc-raised-button{
        --mdc-protected-button-container-height:28px
    }
    .mat-mdc-unelevated-button{
        --mdc-filled-button-container-height:28px
    }
    .mat-mdc-outlined-button{
        --mdc-outlined-button-container-height:28px
    }

    .mat-mdc-icon-button{
        --mdc-icon-button-icon-color:inherit;
        --mdc-icon-button-disabled-icon-color:#666;
        --mat-icon-button-state-layer-color:#000;
        --mat-icon-button-ripple-color:rgba(0, 0, 0, 0.1);
        --mat-icon-button-hover-state-layer-opacity:0.04;
        --mat-icon-button-focus-state-layer-opacity:0.12;
        --mat-icon-button-pressed-state-layer-opacity:0.12;
        --mdc-icon-button-state-layer-size: 28px !important;
        --mdc-icon-button-icon-size: 18px !important;
        padding: 2px !important;
        .mat-icon, .material-symbols-sharp{
            width: 24px !important;
            min-width: 24px !important;
            height: 24px !important;
            min-height: 24px !important;
            font-size: 24px !important;
            line-height: 24px !important;
        }
        .mat-mdc-button-touch-target{
            height: 100%;
            width: 100%;
        }
        &.mat-accent .mat-icon, &.mat-accent .material-symbols-sharp {
            color: #000 !important;
        }
        &.mat-primary .mat-icon, &.mat-primary .material-symbols-sharp {
            color: #1a73e8 !important;
        }
        &.mat-warn{
            --mat-icon-button-ripple-color:#f7f7f7;
        }
        &.mat-warn .mat-icon, &.mat-warn .material-symbols-sharp {
            color: #af0000!important;
        }
        &[disabled] {
            .mat-icon, .material-symbols-sharp{
                opacity: 0.38!important;
            }
        }
    }
    .mat-mdc-icon-button.mat-primary{
        --mdc-icon-button-icon-color:#1a73e8;
        --mat-icon-button-state-layer-color:#1a73e8;
        --mat-icon-button-ripple-color:rgba(63, 81, 181, 0.1)
    }
    .mat-mdc-icon-button.mat-accent{
        --mdc-icon-button-icon-color:#000 !important;
        --mat-icon-button-state-layer-color:#000 !important;
        --mat-icon-button-ripple-color:rgba(255, 64, 129, 0.1)
    }
    .mat-mdc-icon-button.mat-warn{
        --mdc-icon-button-icon-color:#af0000 !important;
        --mat-icon-button-state-layer-color:#af0000 !important;
        --mat-icon-button-ripple-color:rgba(244, 67, 54, 0.1)
    }
    .mat-mdc-icon-button.mat-green{
        --mdc-icon-button-icon-color:#009845 !important;
        --mat-icon-button-state-layer-color:#009845 !important;
        --mat-icon-button-ripple-color:rgba(244, 67, 54, 0.1)
    }
    .mat-mdc-icon-button.mat-orange{
        --mdc-icon-button-icon-color:#b0531e !important;
        --mat-icon-button-state-layer-color:#b0531e !important;
        --mat-icon-button-ripple-color:rgba(244, 67, 54, 0.1)
    }
    .mat-mdc-icon-button.mat-mdc-button-base{
        --mdc-icon-button-state-layer-size:48px;
        width:var(--mdc-icon-button-state-layer-size);
        height:var(--mdc-icon-button-state-layer-size);
        padding:12px
    }    

    .mat-mdc-fab, .mat-mdc-mini-fab{
        box-shadow: none;
        &:hover, &:active, &:focus{
            box-shadow: none !important;
        }
        .mat-icon, .material-symbols-sharp{
            width: 18px !important;
            min-width: 18px !important;
            height: 18px !important;
            min-height: 18px !important;
            font-size: 18px !important;
            line-height: 18px !important;
        }
        .mat-mdc-button-touch-target, .mat-mdc-button-touch-target {
            position: absolute;
            top: 50%;
            height: 100%;
        }
        &[disabled] {
            .mat-icon, .material-symbols-sharp{
                opacity: 0.38!important;
                color: var(--mat-fab-disabled-state-foreground-color)!important;
            }
        }
    }
    .mdc-fab {
        width: 42px !important;
        height: 42px !important;
    }
    .mat-mdc-mini-fab{
        width: 32px !important;
        height: 32px !important;
    }
    .large-button{
        width: 160px !important;
    }
    .medium-button{
        width: 120px !important;
    }
    .small-button{
        width: 80px !important;
    }
    .one-letter{
        padding: 0px !important;
        width: 100% !important;
        min-width: auto !important;
    }
    button.round-button{
        border-radius: 4px !important;
    }
    .mdc-fab--extended{
        -moz-osx-font-smoothing:grayscale;
        -webkit-font-smoothing:antialiased;
        font-family:var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Karla, Roboto, "Helvetica Neue", sans-serif));
        font-size:var(--mdc-typography-button-font-size, 14px);
        line-height:var(--mdc-typography-button-line-height, 28px);
        font-weight:var(--mdc-typography-button-font-weight, 500);
        letter-spacing:var(--mdc-typography-button-letter-spacing, 0px);
        text-decoration:var(--mdc-typography-button-text-decoration, none);
        text-transform:var(--mdc-typography-button-text-transform, none);
        border-radius: 0px !important;
        height: 28px !important;
        padding: 0px !important;
        border: none;
        .mdc-button__label{
            font-variant: unset;
            font-size: 1em;
        }
        .mat-icon, .material-symbols-sharp{
            font-size: 0.875rem !important;
            width: auto !important;
            min-width: auto !important;
            margin: 0 5px 0 0px;
            font-variant: none;
            height: 100% !important;
            min-height: 100% !important;
            line-height: 29px !important;
            color: #000 !important;
            vertical-align: top;
        }
        &.mat-primary{
            border: 1px solid #1c4587 !important;
            .mat-icon, .material-symbols-sharp{
                color: #1c4587 !important;
            }
        }
        &.mat-accent{
            .mat-icon, .material-symbols-sharp{
                color: #fff !important;
            }
        }
        &.mat-warn{
            border: 1px solid #af0000 !important;
            .mat-icon, .material-symbols-sharp{
                color: #af0000 !important;
            }
        }
        &:hover, &:active, &:focus{
            box-shadow: none !important;
            color: #fff !important;
            background-color: #1c4587 !important;
            border-color: none !important;
            .mat-icon, .material-symbols-sharp{
                color: #fff !important;
            }
        }
        &.mat-warn{
            &:hover, &:active, &:focus{
                box-shadow: none !important;
                color: #000 !important;
                background-color: #ffd966 !important;
                border-color: #000 !important;
                .mat-icon, .material-symbols-sharp{
                    color: #000 !important;
                }
            }
        }
        &.mat-primary{
            &:hover, &:active, &:focus{
                background-color: #1c4587 !important;
                border-color: #1c4587 !important;
                color: #fff !important;
                .mat-icon, .material-symbols-sharp{
                    color: #fff !important;
                }
            }
        }
        &[disabled] {
            .mat-icon, .material-symbols-sharp{
                opacity: 0.38!important;
                color: var(--mat-fab-disabled-state-foreground-color) !important;
            }
        }
    }
    .mat-mdc-button, .mdc-button{
        &.mat-accent{
            &:hover, &:active, &:focus{
                box-shadow: none !important;
                border: none !important;
                background-color: #1c4587 !important;
                color: #fff !important;
            }
        }
        &.mat-warn{
            border: 1px solid #af0000 !important;
            &:hover, &:active, &:focus{
                box-shadow: none !important;
                border: 1px solid #000 !important;
                background-color: #ffd966 !important;
                color: #000 !important;
            }
        }
        &:hover, &:active, &:focus{
            box-shadow: none !important;
            border: none !important;
        }
    }
    .mat-mdc-button-base {
        vertical-align: top;
    }
    .mat-mdc-outlined-button{
        width: 100%;
        height: 28px;
        background: #fff;
        &.fileInput{
            height: 100px;
            max-height: none;
            min-height: auto;
            border-radius: 0px !important;
        }
    }
    .fileInput {
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 0px !important;
        border: 1px solid #ccc !important;
        span.mdc-button__label {
            width: 100%;
            padding: 0px;
            height: 100%;
        }
        img{
            width: 90%;
        }
        mat-icon.mat-icon {
            font-size: 54px;
        }
        span.file-text {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            line-height: 100px;
            opacity: 1;
            transition: all .3s ease-in-out;
        }
        &:hover, &:active, &:focus{
            background: rgba(0,0,0,0.5) !important;
            span.file-text {
                opacity: 1;
            }
        }
        &.imgInput{
            img {
                width: auto;
                max-width: 100%;
                max-height: 100%;
            }
            img ~ span.file-text {
                opacity: 0;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    span.filename {
        line-height: 28px;
        display: inline-block;
    }
}

html{
    --mdc-text-button-label-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-text-button-label-text-size:14px;
    --mdc-text-button-label-text-tracking:normal;
    --mdc-text-button-label-text-weight:500;
    --mdc-text-button-label-text-transform:none;
    --mdc-filled-button-label-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-filled-button-label-text-size:14px;
    --mdc-filled-button-label-text-tracking:normal;
    --mdc-filled-button-label-text-weight:500;
    --mdc-filled-button-label-text-transform:none;
    --mdc-outlined-button-label-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-outlined-button-label-text-size:14px;
    --mdc-outlined-button-label-text-tracking:normal;
    --mdc-outlined-button-label-text-weight:500;
    --mdc-outlined-button-label-text-transform:none;
    --mdc-protected-button-label-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-protected-button-label-text-size:14px;
    --mdc-protected-button-label-text-tracking:normal;
    --mdc-protected-button-label-text-weight:500;
    --mdc-protected-button-label-text-transform:none;

    --mdc-fab-container-shape:50% !important;
    --mdc-fab-icon-size:18px;

    --mdc-fab-container-color:white;
    --mat-fab-foreground-color:black;
    --mat-fab-state-layer-color:#000;
    --mat-fab-ripple-color:rgba(0, 0, 0, 0.1);
    --mat-fab-hover-state-layer-opacity:0.04;
    --mat-fab-focus-state-layer-opacity:0.12;
    --mat-fab-pressed-state-layer-opacity:0.12;
    --mat-fab-disabled-state-container-color:#fff;
    --mat-fab-disabled-state-foreground-color:#666;

    --mdc-extended-fab-label-text-font:Karla, Roboto, "Helvetica Neue", sans-serif;
    --mdc-extended-fab-label-text-size:14px;
    --mdc-extended-fab-label-text-tracking:normal;
    --mdc-extended-fab-label-text-weight:500;
}

html .mat-mdc-fab.mat-primary,html .mat-mdc-mini-fab.mat-primary{
    --mdc-fab-container-color: #fff;
    --mat-fab-foreground-color:#1c4587;
    --mat-fab-state-layer-color:#1c4587;
    --mat-fab-ripple-color:rgba(255, 255, 255, 0.1)
}
html .mat-mdc-fab.mat-accent,html .mat-mdc-mini-fab.mat-accent{
    --mdc-fab-container-color:#1155cc !important;
    --mat-fab-foreground-color:#fff !important;
    --mat-fab-state-layer-color:#1c4587;
    --mat-fab-ripple-color:rgba(255, 255, 255, 0.1)
}
html .mat-mdc-fab.mat-warn,html .mat-mdc-mini-fab.mat-warn{
    --mdc-fab-container-color:#fff !important;
    --mat-fab-foreground-color:#af0000;
    --mat-fab-state-layer-color:#fff;
    --mat-fab-ripple-color:rgba(255, 255, 255, 0.1)
}