html{
  --mat-legacy-button-toggle-height:36px;
  --mat-legacy-button-toggle-shape:2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity:1;
  --mat-standard-button-toggle-shape:4px;
  --mat-standard-button-toggle-hover-state-layer-opacity:0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity:0.12
}
html{
  --mat-legacy-button-toggle-text-color:rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color:rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color:rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color:#e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color:rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color:#eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color:#bdbdbd;
  --mat-standard-button-toggle-text-color:rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color:white;
  --mat-standard-button-toggle-state-layer-color:black;
  --mat-standard-button-toggle-selected-state-background-color:#e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color:rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color:rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color:white;
  --mat-standard-button-toggle-disabled-selected-state-text-color:rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color:#bdbdbd;
  --mat-standard-button-toggle-divider-color:#e0e0e0
}
html{
  --mat-standard-button-toggle-height:48px
}
html{
  --mat-legacy-button-toggle-label-text-font:Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height:24px;
  --mat-legacy-button-toggle-label-text-size:16px;
  --mat-legacy-button-toggle-label-text-tracking:0.03125em;
  --mat-legacy-button-toggle-label-text-weight:400;
  --mat-standard-button-toggle-label-text-font:Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height:24px;
  --mat-standard-button-toggle-label-text-size:16px;
  --mat-standard-button-toggle-label-text-tracking:0.03125em;
  --mat-standard-button-toggle-label-text-weight:400
}

.mat-primary{
  --mat-full-pseudo-checkbox-selected-icon-color:#3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0
}
html{
  --mat-full-pseudo-checkbox-selected-icon-color:#ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0
}
.mat-accent{
  --mat-full-pseudo-checkbox-selected-icon-color:#ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0
}
.mat-warn{
  --mat-full-pseudo-checkbox-selected-icon-color:#f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0
}