body{
    .ngx-timepicker {
        height: 34px !important;
        border: 1px solid #ccc!important;
        background: #fff;
    }
    .ngx-timepicker-control__input{
        font-size: 0.95em !important;
    }
    .ngx-timepicker__toggle{
        display: none !important;
    }
    .ngx-timepicker__time-colon{
        margin-left: 5px !important;
    }
    .ngx-timepicker-control{
        width: 60px !important;
        font-size: 0.95em !important;
        &.ngx-timepicker-control--active{
            &::after{
                display: none !important;
            }
        }
    }
    .period-control__button{
        font-size: 0.95em !important;
        width: 45px !important;
        &:not(.period-control__button--disabled):focus:after{
            background-color: transparent !important;
        }
    }
    .period-control__arrow{
        margin-left: 5px !important;
    }
    .period-selector{
        padding: 0px !important;
        top: auto !important;
        right: auto !important;
        max-width: 40px !important;
        width: 40px !important;
    }
    .period-selector__button{
        width: 100% !important;
        height: 24px !important;
        padding: 0 10px !important;
        line-height: 24px !important;
        background: #fff !important;
    }
    .period-selector__button--active{
        color: inherit !important;
        background: rgba(148,163,184,.12) !important;
    }
}