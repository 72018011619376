html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1155cc;
}
html {
  --mat-tree-node-min-height: 28px;
}
html {
  --mat-tree-node-text-font: Karla, sans-serif;
  --mat-tree-node-text-size: 0.9rem;
  --mat-tree-node-text-weight: 400;
}

.mat-tree-invisible {
  display: none;
}

.mat-tree ul,
.mat-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.mat-tree .mat-nested-tree-node div[role="group"] {
  padding-left: 15px;
}

.mat-tree div[role="group"] > .mat-tree-node {
  padding-left: 15px;
}

div[matTreeNodeToggle] {
  padding-right: 20px;
}
.pin-link{
  font-size: 14px !important;
  color: #1155cc !important;
  display: none;
  &:hover{
    color: #000 !important;
  }
}
.add-link{
  font-size: 18px !important;
  color: #1155cc !important;
  background: #f5f5f5;
  border: 1px solid #ccc;
  display: none;
  &:hover{
    color: #000 !important;
  }
}
.mat-tree-node,
.mat-nested-tree-node {
  cursor: pointer;
  text-transform: capitalize;
  line-height: 28px;
  position: relative;
  a {
    border-bottom: 1px solid #1155cc;
    line-height: 20px;
    font-size: 0.8rem;
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  &:hover{
    .pin-link{
      display: inline-block;
    }
    .add-link{
      display: inline-block;
    }
  }
  .material-symbols-sharp {
    font-variant: normal;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    vertical-align: middle;
  }
  > div:first-child {
    white-space: nowrap !important;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 35px);
  }
}
.tree-menu, .add-tree{
  .mat-mdc-progress-spinner {
    position: absolute;
    top: 7px;
    margin-left: -8px;
  }
}
.keep-open {
  position: absolute;
  right: 5px;
  font-size: 16px !important;
  line-height: 28px !important;
  color: #1155cc !important;
  cursor: pointer;
  top: 0px;
}

.drag-tree {
  color: #cf5050 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  cursor: grab;
  position: absolute;
  left: 0px;
}
.tree-checkbox {
  position: absolute;
  right: 0px;
  top: 1px;
  width: 70px;
  text-align: center;
  .mat-mdc-checkbox {
    margin: 0px 0px 0px 0px;
  }
}
.edit-icon-tree {
  position: absolute;
  right: 70px;
  top: 0px;
  width: 70px;
  text-align: center;
  height: 28px;
  mat-icon {
    margin: 6px 0px 0px 0px;
    font-size: 1.2rem;
    cursor: pointer;
  }
}
.move-icon-tree {
  position: absolute;
  right: -5px;
  top: 0px;
  mat-icon {
    margin: 0px 70px 0px 0px;
    font-size: 1.2rem;
  }
  cursor: pointer;
}
.add-tree {
  .mat-tree:after {
    content: "Archive";
    position: absolute;
    top: -15px;
    right: 0px;
    width: 70px;
    text-align: center;
  }
  .mat-tree:before {
    content: "Edit";
    position: absolute;
    top: -15px;
    right: 70px;
    width: 70px;
    text-align: center;
  }
  div[matTreeNodeToggle] {
    padding-right: 40px;
    padding-left: 20px;
  }
}

[aria-expanded="true"] {
  color: #000;
  a {
    color: #000 !important;
    border-bottom-color: #000;
    &.node-archived{
      border-bottom-color: #000 !important;
    }
  }
  .material-symbols-sharp {
    color: #000;
  }
}

[aria-expanded="false"] {
  color: #1155cc;
  a {
    color: #1155cc !important;
    border-bottom: 1px solid transparent;
  }
  .material-symbols-sharp {
    color: #1155cc;
  }
  &:hover {
    a {
      border-bottom-color: #000;
      color: #000 !important;
      &.node-archived{
        border-bottom-color: #000 !important;
      }
    }
    .material-symbols-sharp {
      color: #000;
    }
  }
}
body {
  .mat-mdc-icon-button.mat-mdc-button-base.expand-btn{
    width: 20px;
    height: 20px;
    .mat-mdc-button-ripple, .mdc-icon-button__ripple{
      display: none;
    }
    .mat-icon {
      width: 20px !important;
      min-width: 20px !important;
      height: 20px !important;
      min-height: 20px !important;
      font-size: 18px !important;
      line-height: 26px !important;
    }
  }
  .add-tree{
    .mat-mdc-icon-button.mat-mdc-button-base.expand-btn{
      width: 20px;
      height: 20px;
      .mat-icon {
        width: 20px !important;
        min-width: 20px !important;
        height: 20px !important;
        min-height: 20px !important;
        font-size: 18px !important;
        line-height: 17px !important;
      }
    }
  }
  .mat-mdc-select-panel mat-tree.mat-tree .mat-tree-node{
    width: 100%;
    display: block;
    &:hover{
      background: #f2f4f6;
      .mat-mdc-option, .mat-mdc-option:hover{
          background: #f2f4f6;
      }
    }
  }
  .node-archived {
    padding: 0 2px;
    border-color: #666 !important;
    color: #666 !important;
  }
}