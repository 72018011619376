$color_elf_green_approx: #07796a;
$color_salem_approx: #009845;
$color_carnation_approx: #ff5052;
$white_35: hsla(0,0%,100%,.35);
$white_65: hsla(0,0%,100%,.65);
$white: #fff;
$black_35: rgba(0,0,0,.35);
$black_65: rgba(0,0,0,.65);
$black: #000;
$color_gull_gray_approx: #9fb0b9;
$color_smalt_blue_approx: #5f7c8a;
$black_20: rgba(0,0,0,.2);
$black_8: rgba(0,0,0,.08);
$black_2: rgba(0,0,0,.02);
$color_rose_bud_cherry_approx: #870d4e;
$color_porcelain_approx: #ebeef0;
$black_25: rgba(0,0,0,.25);
$color_lipstick_approx: #0285ce;
$color_nobel_approx: #b2b2b2;
$porcelain: #eff2f3;
$color_coral_approx: #ff7550;
$color_rouge_approx: #9f3d71;
$color_honey_flower_approx: #611c7d;
$color_daisy_bush_approx: #4527a0;
$color_eminence_approx: #71338a;
$color_resolution_blue_approx: #002280;
$color_firefly_approx: #122833;
$color_international_klein_blue_approx: #002ba3;
$color_jazzberry_jam_approx: #b90c5a;
$color_meteor_approx: #cd8307;
$mercury: #e5e5e5;
$black_19: rgba(0,0,0,.19);
$color_monza_approx: #cf0002;
$color_watercourse_approx: #036a5d;
$color_gulf_blue_approx: #001b66;
$color_cod_gray_approx: #1a1a1a;
$color_flush_mahogany_50_approx: rgba(193,64,71,.5);
$black_5: rgba(0,0,0,.05);
$color_flush_mahogany_approx: #c14047;
$color_rose_bud_cherry_50_approx: rgba(135,13,78,.5);
$color_flush_mahogany_10_approx: rgba(193,64,71,.1);
$black_60: rgba(0,0,0,.6);
$color_smalt_blue_95_approx: rgba(95,124,138,.95);
$white_50: hsla(0,0%,100%,.5);
$color_lipstick_50_approx: rgba(196,16,97,.5);
$color_purple_heart_50_approx: rgba(94,53,177,.5);
$color_lipstick_60_approx: rgba(196,16,97,.6);
$color_purple_heart_60_approx: rgba(94,53,177,.6);
$color_mine_shaft_approx: #202020;
$black_40: rgba(0,0,0,.4);
$black_10: rgba(0,0,0,.1);
$black_30: rgba(0,0,0,.3);
$black_75: rgba(0,0,0,.75);
$black_55: rgba(0,0,0,.55);
$color_geyser_approx: #dfe5e8;
$color_silver_approx: #ccc;
$color_purple_heart_approx: #5e35b1;
$color_bali_hai_approx: #7f96ac;
$black_16: rgba(0,0,0,.16);
$color_azure_radiance_approx: #0078ff;
$color_dove_gray_approx: #666;
$color_carnation_10_approx: rgba(255,80,82,.1);
$black_71: rgba(0,0,0,.71);
$color_martini_approx: #999;
$color_logo_1: #C00;
$color_logo_2: #292929;
$color_footer_gray: #ddd;
$color_menu_gray: #646464;
$color_placeholder: #969696;
$color_footer_bg: #dbe1ec;
$color_tab_font: #0b5394;
$color_menu_blue: #1c4587;